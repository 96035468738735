<ng-container *ngIf="logged">
  <p-panel header="Changelogs" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
    <div class="row">
      <div id="markdown-content" class="col-md-12">
        <markdown [src]="'assets/markdown/changelogs/changelogs.md'"></markdown>
      </div>
    </div>
  </p-panel>
</ng-container>
<ng-container *ngIf="!logged">
  <p-panel header="Changelogs" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
    <div class="row">
      <div class="col-md-12">
        Accès interdit
      </div>
    </div>
  </p-panel>
</ng-container>
