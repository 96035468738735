// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]4.5-8817(0454e91465)-C02/11/2022-16:12:56-B02/11/2022-16:13:25' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]4.5-8817(0454e91465)-C02/11/2022-16:12:56-B02/11/2022-16:13:25",
  branch: "master",
  latestTag: "4.5",
  revCount: "8817",
  shortHash: "0454e91465",
  longHash: "0454e9146563765ec0c2653e2b081405c2d5758b",
  dateCommit: "02/11/2022-16:12:56",
  dateBuild: "02/11/2022-16:13:25",
  buildType: "Ansible",
  } ;
