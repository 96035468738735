<dx-popup
  [showTitle]="true"
  title="AJOUTER DES DENRÉES"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="displayDialog"
  [fullScreen]="fullScreen"
  (onHiding)="closeDialog()"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>
  <div class="mg-b-10">
    <p-panel header="Options de recherche" [toggleable]="true" (onAfterToggle)="onToggleSearchPanel($event)">
      <div class="row">
        <div class="col-md-2">
          <dx-text-box valueChangeEvent="keyup" (onValueChanged)="announceDenreeLibelleSearch($event)"
                       [value]="denreeSearchValue" placeholder="Déclinaison">
          </dx-text-box>
        </div>
        <div class="col-md-3">
          <dx-tag-box
            [dataSource]="appellationList"
            placeholder="Filtrer par appellation..."
            displayExpr="libelle"
            valueExpr="id"
            [multiline]="false"
            [showSelectionControls]="true"
            (onValueChanged)="onChangeAppellation($event)"
            [value]="appellationListSelected">
          </dx-tag-box>
        </div>
        <div class="col-md-2">
          <dx-tag-box
            [dataSource]="allergeneList"
            placeholder="Filtrer par allergène..."
            displayExpr="libelle"
            valueExpr="id"
            [multiline]="false"
            [showSelectionControls]="true"
            (onValueChanged)="onChangeAllergene($event)"
            [value]="allergeneListSelected">
          </dx-tag-box>
        </div>
        <div class="col-md-2">
          <dx-tag-box
            [dataSource]="declinaisonList"
            placeholder="Filtrer par déclinaison..."
            displayExpr="libelle"
            valueExpr="id"
            [multiline]="false"
            [showSelectionControls]="true"
            (onValueChanged)="onChangeDeclinaison($event)"
            [value]="declinaisonListSelected">
          </dx-tag-box>
        </div>
        <div class="col-md-3">
          <dx-drop-down-box
            [value]="treeBoxValue"
            valueExpr="id"
            displayExpr="name"
            placeholder="Filtrer par famille de produit..."
            [showClearButton]="true"
            [dataSource]="treeDataSource"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #treeViewProduitDeclinaison
                [dataSource]="familleProduitList"
                [height]="150"
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="parent.id"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="true"
                displayExpr="libelle"
                [selectByClick]="true"
                (onItemSelectionChanged)="onChangeParentDenreeSelection($event)"
              >
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </p-panel>
  </div>

  <div *ngIf="produitDeclinaisonListSelected.length" class="row mg-b-5">
    <div class="col-md-12">
      <i class="fas fa-apple-alt mg-r-5"></i> <strong class="mg-r-5 inline">Contenu panier : </strong>
    </div>
  </div>
  <div *ngIf="produitDeclinaisonListSelected.length" class="row">
    <div class="col-md-12">
      <p-chips #basketProduitDeclinaison
               id="basketProduitDeclinaison"
               (keydown)="utils.onChangeDisabledInputChips($event)"
               [(ngModel)]="produitDeclinaisonListSelected"
               (onRemove)="onRemoveChipProduitDeclinaison($event)">
        <ng-template let-item pTemplate="item">
          <i style="margin-right:2em; padding-bottom: 2px;vertical-align: middle"> {{item.libelle}}</i>
        </ng-template>
      </p-chips>
    </div>
  </div>

  <dx-data-grid
    [dataSource]="customStore"
    [remoteOperations]="true"
    keyExpr="id"
    height="65%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #gridAjoutDenreePropositionCommande>
    <dxi-column dataField="id" alignment="center" caption="Actions" [width]="80"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="libelle" alignment="left" caption="Déclinaison"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="code" alignment="left" caption="Code"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="site" alignment="left" caption="Site"
                [allowFiltering]="true"
                cellTemplate="siteCellTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'siteCellTemplate'">
      {{cell.row.data.site.libelle}}
    </div>


    <!--    TEMPLATE-->
    <div *dxTemplate="let cell of 'actionsCellTemplate'">
      <yo-cell-button (yoNavigation)="addDenreeToBasket(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fas fa-plus'"
                      pTooltip="ajouter la déclinaison à la selection"
                      tooltipPosition="right"
                      showDelay="500">
      </yo-cell-button>
    </div>

    <!--    OPTION-->
    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[20, 50, 100, 500]"
      [showNavigationButtons]="false"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} Déclinaison(s)">
    </dxo-pager>
  </dx-data-grid>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div>
        <label class="font-12 genlabel">
          <i class="fas fa-info-circle mg-r-5"></i> Veuillez ajouter des denrées à votre panier
        </label>
      </div>
    </div>
    <div>
      <span class="mg-r-5">
         <button pButton icon="fas fa-plus" (click)="sendProduitDeclinaisonList()"
                 [disabled]="!produitDeclinaisonListSelected.length" pTooltip="Affection du contenu du panier"
                 tooltipPosition="top"
                 showDelay="500" class="p-button-success">
        </button>
      </span>
      <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </span>
    </div>
  </div>
</dx-popup>
