<div class="row ">
  <div class="col-md-12">

    <div class="card">
      <div class="card-body">
        <div class="card-columns">

          <div class="card  " *ngIf="hasAdministration$ | async">
            <div class="card-header">
              <i class="fa fa-wrench site-secondaire-color mg-r-5"></i>RÉFÉRENTIEL
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <p-panelMenu [model]="referentielItems"></p-panelMenu>
              </div>
            </div>
          </div>

          <div class="card " *ngIf="hasGestionFournisseurs$ | async">
            <div class="card-header">
              <i class="fa fa-truck site-secondaire-color mg-r-5"></i>FOURNISSEURS
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton routerLink="/gestion-fournisseurs"
                        label="Voir la liste des fournisseurs"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main"
                >
                </button>
              </div>

              <div class="mg-t-5">
                <button pButton routerLink="/gestion-fournisseurs/articles/article-affecte"
                        label="Mise à jour des articles"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main"
                >
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton routerLink="/gestion-marche"
                        label="Marchés"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main"
                >
                </button>
              </div>
            </div>
          </div>

          <div class="card " *ngIf="hasUniteDeProduction$ | async">
            <div class="card-header">
              <i class="fa fa-industry site-secondaire-color mg-r-5"></i>UNITÉS DE PRODUCTION
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton routerLink="/gestion-unitesproduction"
                        label="Voir les unités de production"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main"
                        pTooltip="Unités de production" showDelay="500">
                </button>
              </div>
            </div>
          </div>

          <div class="card " *ngIf="gestionProduitsIapro || gestionProduitsIprod">
            <div class="card-header">
              <i class="fas fa-carrot  mg-r-5 site-secondaire-color"></i>PRODUITS
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton routerLink="/gestionproduits"
                        label="Voir les produits"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>


          <div class="card " *ngIf="hasGestionMenus$ | async">
            <div class="card-header">
              <i class="fas fa-utensils mg-r-5 site-secondaire-color"></i> MENUS
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton routerLink="/gestiongemrcn"
                        label="Modèles de menus"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton routerLink="/gestioncontrats"
                        label="Offres alimentaires"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton routerLink="/gestionmenus"
                        label="Planning des menus"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>


          <div class="card " *ngIf="hasGestionClients$ | async">
            <div class="card-header">
              <i class="fas fa-users mg-r-5 site-secondaire-color"></i> CLIENTS
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button *ngIf="hasGestionPlc$ | async"
                        pButton routerLink="/gestion-plc/menu-plc"
                        label="Planning des effectifs"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button *ngIf="hasGestionPlc$ | async"
                        pButton routerLink="/gestion-clients"
                        label="Clients et points de livraison"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
                <button *ngIf="hasPortailUtilisateur$ | async"
                        pButton routerLink="/gestion-plc/portail-plc"
                        label="Portail de saisie des effectifs"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>


          <div class="card " *ngIf="hasGestionBesoins$ | async">
            <div class="card-header">
              <i class="fas fa-box site-secondaire-color mg-r-5"></i> BESOINS
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton routerLink="/gestion-listes-besoins/lb/listes-de-besoins"
                        label="Voir les besoins"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>


          <div class="card " *ngIf="(hasCommandesTableauBord$ || hasGestionCommandes$) | async">
            <div class="card-header">
              <i class="fa fa-shopping-cart site-secondaire-color mg-r-5"></i>COMMANDES FOURNISSEURS
            </div>
            <div class="card-body">

              <div class="mg-t-5">
                <button pButton *ngIf="hasCommandesTableauBord$ | async"
                        routerLink="/gestion-commandes-fournisseurs/bc/tableau-de-bord"
                        label="Tableau de bord"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton *ngIf="hasGestionCommandes$ | async"
                        routerLink="/gestion-commandes-fournisseurs/bc/propositions"
                        label="Propositions de commande"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton *ngIf="hasGestionCommandes$ | async"
                        routerLink="/gestion-commandes-fournisseurs/bc/refusees"
                        label="Commandes refusées"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">

                <button pButton *ngIf="hasGestionCommandes$ | async"
                        routerLink="/gestion-commandes-fournisseurs/bc/a-valider"
                        label="Commandes à valider"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">

                <button pButton *ngIf="hasStocks$ | async"
                        routerLink="/gestion-commandes-fournisseurs/bc/a-receptionner"
                        label="Commandes à réceptionner"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton *ngIf="hasStocks$ | async"
                        routerLink="/gestion-commandes-fournisseurs/bc/receptions"
                        label="Commandes réceptionnées"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">

                <button pButton *ngIf="hasFacturation$ | async"
                        routerLink="/gestion-commandes-fournisseurs/bc/factures"
                        label="Commandes facturées"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>


          <div class="card " *ngIf="hasStocks$ | async">
            <div class="card-header">
              <i class="fas fa-cubes site-secondaire-color mg-r-5"></i>STOCKS
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton
                        routerLink="/gestion-stocks/stocks/matieres-premieres"
                        label="Matières premières"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton
                        routerLink="/gestion-stocks/mouvements/grille-mouvements"
                        label="Mouvements"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton
                        routerLink="/gestion-stocks/stocks/inventaire"
                        label="Inventaire"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>

          <div class="card " *ngIf="hasGestionProduction$ | async">
            <div class="card-header">
              <i class="fas fa-newspaper site-secondaire-color mg-r-5"></i>PRODUCTION
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton
                        routerLink="/gestion-production/pp/plans-de-production"
                        label="Plans de production"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>

          <div class="card " *ngIf="hasGestionIDistri$ | async">
            <div class="card-header">
              <i class="fas fa-boxes site-secondaire-color mg-r-5"></i>CONDITIONNEMENTS
            </div>
            <div class="card-body">
              <div class="mg-t-5">
                <button pButton
                        routerLink="/gestion-conditionnements/modeles-plats"
                        label="Modèles de plats"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
              <div class="mg-t-5">
                <button pButton
                        routerLink="/gestion-conditionnements/modeles-plc"
                        label="Modèles Points de livraison"
                        icon="fas fa-arrow-right"
                        class="p-button-secondary main">
                </button>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>


  </div>
</div>

