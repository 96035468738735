<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div class="d-flex flex-rowa align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          (click)="openSignatureUtilisateur(undefined)"
          [disabled]="!canCreateAndDelete()"
          pTooltip="Créer une signature utilisateur">
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canCreateAndDelete() || !selectedRows.length"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="signaturesUtilisateurList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="templateSite">
  </dxi-column>
  <dxi-column dataField="nom" caption="Nom" [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="prenom" caption="Prénom" [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column caption="Image" [width]="100"
              [allowFiltering]="false" cellTemplate="cellTemplate">
  </dxi-column>
  <div *dxTemplate="let cell of 'cellTemplate'">
    <yo-img-entity *ngIf="!cell.row.data"
                   [entityId]="cell.row.data.id" [refresh]="1"
                   [height]="50"
                   format="thumb"
                   [displayNoImage]="true"
                   [title]="cell.row.data.libelle"
                   [entityName]="entityName"
                   [ordre]="1"></yo-img-entity>
    <yo-img-entity *ngIf="cell.row.data"
                   [entityId]="cell.row.data.id" [refresh]="1"
                   [height]="50"
                   [openable]="true"
                   format="thumb"
                   [displayNoImage]="true"
                   [title]="cell.row.data.libelle"
                   [entityName]="entityName"
                   [ordre]="1"></yo-img-entity>
  </div>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <yo-cell-button (yoNavigation)="openSignatureUtilisateur(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoViewMode]="!canModify(cell.row.data)"
                    [yoIconClass]="canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [pTooltip]="canModify(cell.row.data)?'Modifier la signature utilisateur':'Voir la signature utilisateur'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'templateSite'">
    <yo-site [siteLibelle]="cell.row.data.site?.libelle"
             [siteId]="cell.row.data.site?.id"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} signatures(s) utilisateur">
  </dxo-pager>

</dx-data-grid>

<yo-pms-signature-utilisateur-dialog></yo-pms-signature-utilisateur-dialog>


