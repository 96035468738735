import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {Utilisateur} from './core/utilisateur';
import {ActivationEnd, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {HEADER_HEIGHT_PX, UtilsService} from './core/utils/utils.service';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from './core/services/security/auth2.service';
import {PreferencesUtilisateurService} from './core/services/preferences-utilisateur.service';
import {Location} from '@angular/common';
import {EnvironnementDTO} from 'app/core/dtos/environnement-dto';
import {LoadingService} from './core/services/loading.service';
import {DOL_VERSION_JSON} from '../dol-version';
import {RequirementsService} from './core/services/requirements.service';
import {catchError, debounceTime, filter, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/index';
import {DevextremeService} from './core/services/technique/devextreme.service';
import {MSG_POSITION, MSG_SEVERITY, PRIMENG_TRANSLATIONS} from './core/constants';
import {ItemNavigationService} from './core/services/technique/item-navigation.service';
import {MenuItem, PrimeNGConfig} from "primeng/api";
import {Menu} from "primeng/menu";
import {ToastService} from "./core/services/technique/toast.service";

type BANDEAU_WIDTH_TYPE = 'small' | 'long';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  MSG_POSITION = MSG_POSITION;
  MSG_SEVERITY = MSG_SEVERITY;
  HEADER_HEIGHT_PX = HEADER_HEIGHT_PX;


  @ViewChild('mainContent') mainContent: ElementRef;
  @ViewChild('sidebar') sidebar: ElementRef;


  @ViewChild('menuReferentiel') menuReferentiel;
  @ViewChild('menuFournisseurs') menuFournisseurs;
  @ViewChild('menuUnitesProd') menuUnitesProd;
  @ViewChild('menuProduits') menuProduits;
  @ViewChild('menuMenus') menuMenus;
  @ViewChild('menuClients', {static: false}) menuClients;
  @ViewChild('menuBesoins') menuBesoins;
  @ViewChild('menuProduction') menuProduction;
  @ViewChild('menuCommandesFournisseurs') menuCommandesFournisseurs;
  @ViewChild('menuStocks') menuStocks;
  @ViewChild('loggedIn') loggedIn;
  @ViewChild('environnement') environnement;
  @ViewChild('menuEnvironnements') menuEnvironnements: Menu;

  isBlockedUI: boolean = false;

  tailleBandeauMenu = 0;
  bandeauWidthType: BANDEAU_WIDTH_TYPE = 'long';
  private subjectTailleMenus = new Subject();
  tailleMenus$ = this.subjectTailleMenus.asObservable();


  dolVersionJson = DOL_VERSION_JSON;
  // todo a chercher par une  route
  dolUrl = '';
  dolBDD = '';

  user$: Observable<Utilisateur>;
  isLoggedIn$: Observable<boolean>;
  gestionProduitsIprod = false;
  gestionProduitsIapro = false;


  environnement$;

  isLoading = false;

  selectedEnvironnementDTO: EnvironnementDTO;

  subPrefsUser: Subscription;
  subAuth2: Subscription;
  subChangeEnvironnement: Subscription;
  subLoading: Subscription;
  subTailleMenus: Subscription;
  subPrimaryRoute: Subscription;
  subSecondaryRoute: Subscription;

  subNotifsInfos: Subscription;
  subToast: Subscription;

  // DOM
  window;

  itemsMenuEnvironnements: MenuItem[] = [];

  isVisible: boolean = false;
  type: string;
  message: any;
  key: string;
  urlMessage: string;
  timeMessage: number;
  notificationsInfos: any;
  displayNotificationsInfos: boolean;

  refreshPageOptions: any;

  constructor(private _location: Location,
              private router: Router,
              public utils: UtilsService,
              private requirementsSvc: RequirementsService,
              public title: Title,
              public loadingSvc: LoadingService,
              public auth2Svc: Auth2Service,
              private cd: ChangeDetectorRef,
              private devExtremeSvc: DevextremeService,
              private toastSvc: ToastService,
              public itemNavSvc: ItemNavigationService,
              public preferencesUtilisateurService: PreferencesUtilisateurService,
              private primeNgConfigSvc: PrimeNGConfig) {

    title.setTitle('DATAMEAL ONLINE');
    this.window = window;

    let self = this;
    this.refreshPageOptions = {
      icon: 'check',
      text: 'OK',
      onClick(e) {
        self.refreshPage();
      },
    };

    // localization
    this.devExtremeSvc.getLocalization(navigator.language);
    this.primeNgConfigSvc.setTranslation(PRIMENG_TRANSLATIONS);

    // detecter la route primaire
    this.detectPrimaryRoute();
    // si la route secondaire existe il va ouvrir la sidenav
    this.detectSecondaryRoute();
  }


  ngOnInit(): void {

    this.selectedEnvironnementDTO = this.auth2Svc.selectedEnvironnementDTO;

    this.subToast = this.toastSvc.displayToast$
      .subscribe((result: any) => {
        this.isVisible = true;
        this.message = result?.message;
        this.type = result?.type;
        this.key = result?.key;
        this.urlMessage = result?.urlMessage;
        this.timeMessage = result.time;
      });

    this.subNotifsInfos = this.utils.notificationsInfos$
      .subscribe((result: any) => {
        this.displayNotificationsInfos = true;
        this.notificationsInfos = result.message;
      });

    // Observer les preferences utilisateurs
    this.preferencesUtilisateurSubscription();

    // Observer la barre de chargement
    this.loadingSubscription();

    // Observer la taille de l'ecran pour adapter le bandeau
    this.tailleMenusSubscription();

    // si la version datameal online n'est pas à jour, on essaie de la mettre à jour
    this.utils.updateApplicationVersion();

    // gestion de droits iappro/iprod
    this.iprodIApproSubscription();


    this.environnement$ = this.auth2Svc.environnement$;
    this.user$ = this.auth2Svc.user$;
    this.isLoggedIn$ = this.auth2Svc.isLoggedIn$;

    this.utils.subjectBlockui$.subscribe((isBlocked: boolean) => {
      this.isBlockedUI = isBlocked;
    });

    // Le service worker n'est activé qu'en production
    // if (environment.production) {
    //   this.dolSwSvc.subscribeToNewVersion();
    // }

  }

  refreshPage = (): void => {
    this.window.location.href = "";
  }

  iprodIApproSubscription() {
    this.subAuth2 = this.auth2Svc.hasGestionProduitsIapro$.pipe(
      switchMap(data => {
        this.gestionProduitsIapro = data;
        return this.auth2Svc.hasGestionProduitsIprod$;
      }),
      switchMap(data => {
        this.gestionProduitsIprod = data;
        return of(null);
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe();
  }

  /**
   * Observer les preferences utilisateurs
   */
  preferencesUtilisateurSubscription() {
    this.subPrefsUser = this.preferencesUtilisateurService.preferencesOfUser$.subscribe(data => {

      this.auth2Svc.utilisateur.preferences = data;

      this.selectedEnvironnementDTO = this.auth2Svc.selectedEnvironnementDTO;

      this.announceTailleBandeauMenu();

      this.cd.markForCheck();

    });

  }

  /**
   * Observer la taille de l'ecran pour adapter le bandeau
   */
  tailleMenusSubscription() {
    this.subTailleMenus = this.tailleMenus$.pipe(
      debounceTime(100),
    ).subscribe(response => {

      this.bandeauWidthType = this.getTailleBandeauMenu();

    });
  }

  /**
   * Activer la barre de chargement
   */
  loadingSubscription() {
    this.subLoading = this.loadingSvc.specificLoading$.subscribe(response => {
      this.isLoading = response;
      this.cd.markForCheck();
    });

  }


  onChangeEnvironnement(environnementDTO: EnvironnementDTO) {

    this.selectedEnvironnementDTO = environnementDTO;

    // on change l'environnement
    this.auth2Svc.changementEnvironnement(this.selectedEnvironnementDTO);
  }

  openPreferences(): void {
    this.preferencesUtilisateurService.announceOpenDialogPreferences();
  }

  logout() {
    this.auth2Svc.logout();
  }

  back() {
    this._location.back();
  }

  next() {
    this._location.forward();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPrefsUser);
    this.utils.unsubscribe(this.subAuth2);
    this.utils.unsubscribe(this.subChangeEnvironnement);
    this.utils.unsubscribe(this.subLoading);
    this.utils.unsubscribe(this.subTailleMenus);
    this.utils.unsubscribe(this.subPrimaryRoute);
    this.utils.unsubscribe(this.subSecondaryRoute);
    this.utils.unsubscribe(this.subToast);
    this.utils.unsubscribe(this.subNotifsInfos);
  }

  announceTailleBandeauMenu() {
    this.subjectTailleMenus.next();
  }

  ngAfterViewInit(): void {

    /*************************
     *  DO NOT REMOVE
     ***************************************/

    // Détection de la compatibilite du péripherique
    this.requirementsSvc.requireCompatibleDevice();

    // sidebar routes secondaires
    this.utils.sidebar = this.sidebar;

    // contenu principal
    this.utils.mainContent = this.mainContent;

    this.utils.getMainContentHeight();

    // lancer l'annonce pour calculer la taille des menus à l'affichage
    this.announceTailleBandeauMenu();

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.announceTailleBandeauMenu();
  }

  getTailleBandeauMenu(): BANDEAU_WIDTH_TYPE {

    let countItems = 0;
    const t1 = this.menuReferentiel ? countItems++ : 0;
    const t2 = this.menuUnitesProd ? countItems++ : 0;
    const t3 = this.menuProduits ? countItems++ : 0;
    const t4 = this.menuMenus ? countItems++ : 0;
    const t5 = this.menuBesoins ? countItems++ : 0;
    const t6 = this.menuProduction ? countItems++ : 0;
    const t7 = this.menuCommandesFournisseurs ? countItems++ : 0;
    const t8 = this.menuStocks ? countItems++ : 0;
    const t11 = this.menuClients ? countItems++ : 0;

    const t9 = this.environnement ? 200 : 0;
    const t10 = this.loggedIn ? 250 : 0;


    // console.log('tailles ',countItems*150, t8, t9);

    // 20 * countItems  = marge de securite
    const taille = (130 * countItems) + t9 + t10 + (10 * countItems);
    this.tailleBandeauMenu = taille;

    if (taille > window.innerWidth) {

      return 'small';
    }


    return 'long';
  }


  /**
   * Retailler la longueur du label
   * @param longLabel
   * @param normalLabel
   */
  getResponsiveLabelMenu(tailleMenus: BANDEAU_WIDTH_TYPE, longLabel: string, normalLabel: string, smallLabel?: string) {

    if (this.bandeauWidthType === 'long') {

      return longLabel;

    } else if (this.bandeauWidthType === 'small') {

      return smallLabel;

    }

    return longLabel;

  }

  getResponsiveLabelLogin(screenWidthType: BANDEAU_WIDTH_TYPE) {

    const prenom = this.auth2Svc.utilisateur.prenom.toUpperCase();
    const nom = this.auth2Svc.utilisateur.nom.toUpperCase();

    if (screenWidthType === 'long') {

      return prenom + ' ' + nom;

    } else if (screenWidthType === 'small') {

      return prenom.substr(0, 1) + ' ' + nom.substr(0, 1);

    }

  }


  onHideSideBar() {
    this.router.navigate([{outlets: {secondary: null}}]);
  }


  /**
   * Si une route secondaire est lancée (sidenav), annoncer aux abonnés que la route a changé
   */
  detectSecondaryRoute() {

    this.subSecondaryRoute = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof ActivationEnd),
      map((event) => {
        const isActive = this.router.getCurrentNavigation().extractedUrl.root.children.secondary !== undefined
        if (isActive) {
          this.utils.sidenav = true;
          this.itemNavSvc.announceSecondaryRoute(event);
        }
      })
    ).subscribe();

  }

  /**
   *  Si une route primaire est lancée , annoncer aux abonnés que la route a changé
   */
  detectPrimaryRoute() {

    this.subPrimaryRoute = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: any) => this.itemNavSvc.announcePrimaryRoute(event));
  }


  initItemsMenuEnvrionnementsAndDisplay(event: any) {

    this.itemsMenuEnvironnements = [];
    for (const environnement of this.auth2Svc.arrayEnvironnementDTOs) {
      this.itemsMenuEnvironnements.push(
        {
          label: environnement.libelle,
          command: (event) => this.onChangeEnvironnement(environnement)
        }
      );
    }
    this.menuEnvironnements.toggle(event);
  }

}
