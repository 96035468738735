import {NgModule} from '@angular/core';
import {ModelePlatResolverService} from './modele-plat-resolver.service';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GestionConditionnementsRootComponent} from './gestionconditionnements.component';
import {GestionConditionnementsRoutingModule} from './gestionconditionnements-routing.module';
import {ParametrageConditionnementModelesPlatsComponent} from './modeles-plats/dialog/parametrage/mp-parametrage.component';
import {PlatsComponent} from './modeles-plats/dialog/plats/mp-plats.component';
import {ModelePlatComponent} from './modeles-plats/dialog/modele-plat.component';
import {
  DxDataGridModule,
  DxDropDownBoxModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTreeListModule,
  DxTreeViewModule
} from 'devextreme-angular';
import {ToolbarModule} from 'primeng/toolbar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DropdownModule} from 'primeng/dropdown';
import {TooltipModule} from 'primeng/tooltip';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {StepsModule} from 'primeng/steps';
import {ModelesPlatsComponent} from './modeles-plats/modeles-plats.component';
import {InputTextModule} from "primeng/inputtext";
import {ModelePlatIdentiteComponent} from "./modeles-plats/dialog/fiche-identite/mp-identite.component";
import {ModelePlatAddConditionnementVarianteDeclinaison} from "./modeles-plats/dialog/parametrage/dialog/mp-add-cv-decli.component";
import {ParametrageConditionnementModelesPlcComponent} from "./modeles-plc/dialog/parametrage/mp-parametrage.component";
import {PointsLivraisonComponent} from "./modeles-plc/dialog/points-livraison/mp-points-livraison.component";
import {ModelePlcIdentiteComponent} from "./modeles-plc/dialog/fiche-identite/mp-identite.component";
import {ModelesPlcComponent} from "./modeles-plc/modeles-plc.component";
import {ModelePlcComponent} from "./modeles-plc/dialog/modele-plc.component";
import {ModelePlcResolverService} from "./modele-plc-resolver.service";
import {ModelePlcRowParametrage} from "./modeles-plc/dialog/parametrage/dialog/mp-row-parametrage.component";
import {PreparationConditionnementComponent} from "./preparation/preparation-conditionnement.component";
import {PreparationCalculConditionnementComponent} from "./preparation/calcul/preparation-calcul-conditionnement.component";
import {PreparationConditionnementResolverService} from "./preparation-conditionnement-resolver.service";
import {PreparationCalculConditionnementDetailsComponent} from "./preparation/calcul/details/preparation-calcul-conditionnement-details.component";
import {MenuModule} from "primeng/menu";
import {DialogAjoutMcpPlatsComponent} from "./modeles-plats/dialog/plats/dialog/dialog-ajout-mcp-plats.component";
import {DialogAjoutMcplcPlcComponent} from "./modeles-plc/dialog/points-livraison/dialog/dialog-ajout-mcplc-plc.component";

@NgModule({
  imports: [
    CommonModule,
    GestionConditionnementsRoutingModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,

    TooltipModule,
    CardModule,
    CheckboxModule,
    PanelModule,
    ListboxModule,
    TabMenuModule,
    DialogModule,
    ButtonModule,
    StepsModule,

    DxTreeListModule,
    ToolbarModule,
    DxDataGridModule,
    OverlayPanelModule,
    DropdownModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    InputTextModule,
    MenuModule,
    DropdownModule,
    DxTagBoxModule,
    DxPopupModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
  ],
  entryComponents: [],
  declarations: [
    GestionConditionnementsRootComponent,
    ParametrageConditionnementModelesPlatsComponent,
    PlatsComponent,
    ModelePlatComponent,
    ModelesPlatsComponent,
    ModelePlatIdentiteComponent,
    ModelePlatAddConditionnementVarianteDeclinaison,
    ParametrageConditionnementModelesPlcComponent,
    PointsLivraisonComponent,
    ModelePlcIdentiteComponent,
    ModelesPlcComponent,
    ModelePlcComponent,
    ModelePlcRowParametrage,
    PreparationConditionnementComponent,
    PreparationCalculConditionnementComponent,
    PreparationCalculConditionnementDetailsComponent,
    DialogAjoutMcpPlatsComponent,
    DialogAjoutMcplcPlcComponent
  ],
  providers: [ ModelePlatResolverService, ModelePlcResolverService, PreparationConditionnementResolverService ]
})
export class GestionConditionnementsModule {
}
