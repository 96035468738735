import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../core/utils/utils.service";
import {Auth2Service} from "../core/services/security/auth2.service";
import {Subscription} from "rxjs";

@Component({
  templateUrl: './changelogs.component.html',
  styleUrls: ['./changelogs.component.scss']
})
export class ChangelogsComponent implements OnInit, OnDestroy, AfterViewInit {

  logged: boolean = false;

  changelogs: string = '';

  subLog: Subscription;

  constructor(public utils:UtilsService, private auth2Svc: Auth2Service) { }

  ngOnInit(): void {
    this.initLoggedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subLog);
  }

  initLoggedSubscription = () => {
    this.subLog = this.auth2Svc.isLoggedIn$
      .subscribe(res => this.logged = res);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document.querySelectorAll("#markdown-content a")
        .forEach((link: HTMLElement) => {
          link.onclick = (event) => {
            event.preventDefault();
            const hrefAttribute: Attr = link.attributes.getNamedItem('href');
            const hrefValue: string = hrefAttribute.value;
            if (hrefValue.startsWith("#")) {
              document.getElementById(hrefValue.substr(1)).scrollIntoView({
                behavior: 'smooth'
              });
            } else {
              window.open(hrefValue, '__blank');
            }
          }
        } );
    }, 1500);
  }

}
