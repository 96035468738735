<dx-popup
  [width]="900"
  [height]="'auto'"
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  (onHiding)="closeDialog()"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div *ngIf="forUpdate && tache?.site || !forUpdate" class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Site <span class="danger-color">*</span></div>
      <div *ngIf="canModify(); else elseBlock" class="col-md-8">
          <dx-lookup
            class="field-customization"
            [value]="siteSelected?.id"
            [items]="sitesList"
            formControlName="site"
            placeholder="Sélectionner un site"
            valueExpr="id"
            displayExpr="libelle"
            searchExpr="libelle"
          >
          </dx-lookup>
      </div>

      <ng-template #elseBlock>
        <div class="col-md-8">
          <yo-site [siteLibelle]="tache.site?.libelle"
                   [siteId]="tache.site?.id"></yo-site>
        </div>
      </ng-template>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Libellé <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <div class="input-group-sm">
          <dx-text-box [disabled]="tache?.isBasic && !canModify()" placeholder="Prise de température" formControlName="libelle"></dx-text-box>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Couleur <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-color-box [disabled]="!canModify()" [(ngModel)]="couleurSelected" formControlName="couleur"></dx-color-box>
        <ng-container
          *ngTemplateOutlet="tplError;context:{form:form,property:'couleur',label:'Couleur'}"></ng-container>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Icône <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <div class="dx-field">
          <dx-lookup
            class="field-customization"
            height="auto"
            [value]="iconPath"
            [items]="imagesPath"
            placeholder="Sélectionner une icône"
            itemTemplate="listItem"
            fieldTemplate="field"
            [searchEnabled]="false"
            [disabled]="!canModify()"
            (valueChange)="updateIcon($event)"
          >
            <div *dxTemplate="let item of 'listItem'">
              <div class="custom-field">
                <img src="{{ item?.path }}" style="width: 25px" alt=""/>
              </div>
            </div>
            <div *dxTemplate="let item of 'field'">
              <div class="custom-field" *ngIf="iconPath">
                <img src="{{ iconPath?.path }}" style="width: 25px" alt=""/>
              </div>
              <div *ngIf="!iconPath" class="dx-lookup-field-wrapper dx-lookup-empty">
                <div class="dx-lookup-field">
                  Sélectionner une icône
                </div>
              </div>
            </div>
          </dx-lookup>
        </div>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Lien externe</div>

      <div class="col-md-4">
        <dx-file-uploader
          [value]="fileSelected"
          selectButtonText="{{tache?.lienExterne ? 'Modifier le fichier' : 'Choisir un fichier'}}"
          labelText=""
          (change)="onFileChange($event)"
          accept="*"
          uploadMode="useForm"
        >
        </dx-file-uploader>
      </div>
      <div class="col-md-4 align-self-center">
        <dx-button
          *ngIf="tache?.lienExterne"
          stylingMode="contained"
          text="Récupérer le fichier"
          type="normal"
          (onClick)="printPDFLienExterne()"
        >
        </dx-button>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Ordre <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-lookup
          [value]="orderSelected?.value"
          [items]="orders"
          placeholder="Sélectionner un ordre"
          valueExpr="value"
          displayExpr="value"
          [searchEnabled]="false"
          [disabled]="!canModify()"
        >
        </dx-lookup>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Type de tâche <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-lookup
          [disabled]="tache?.isBasic"
          [value]="typeTacheSelected?.id"
          [dataSource]="typesTacheDataSource"
          placeholder="Sélectionner un type de tâche"
          valueExpr="id"
          displayExpr="libelle"
          [searchEnabled]="false"
          [disabled]="!canModify()"
        >
        </dx-lookup>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel">Types d'équipement <span class="danger-color">*</span></div>

      <div class="col-md-8">
        <div class="dx-field">
          <dx-drop-down-box
            [(value)]="treeBoxValueTypeEquipement"
            valueExpr="id"
            displayExpr="libelle"
            placeholder="Sélectionner un ou des type(s) d'équipement"
            [showClearButton]="true"
            [dataSource]="treeDataSourceTypeEquipement"
            [disabled]="!canModify()"
            (onValueChanged)="onDropDownBoxValueChanged(TREE_BOX_VALUE_TYPE_EQUIPEMENT)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #treeViewTE
                [dataSource]="treeDataSourceTypeEquipement"
                dataStructure="plain"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                keyExpr="id"
                displayExpr="libelle"
                [selectByClick]="true"
                (onContentReady)="onTreeViewReady($event, TREE_BOX_VALUE_TYPE_EQUIPEMENT)"
                (onItemSelectionChanged)="onTreeViewSelectionChanged($event, TREE_BOX_VALUE_TYPE_EQUIPEMENT)"
              ></dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel">Types d'utilisateur <span class="danger-color">*</span></div>

      <div class="col-md-8">
        <div class="dx-field">
          <dx-drop-down-box
            [(value)]="treeBoxValueTypeUtilisateur"
            valueExpr="id"
            displayExpr="libelle"
            placeholder="Sélectionner un ou des type(s) d'utilisateur"
            [showClearButton]="true"
            [dataSource]="treeDataSourceTypeUtilisateur"
            [disabled]="!canModify()"
            (onValueChanged)="onDropDownBoxValueChanged(TREE_BOX_VALUE_TYPE_UTILISATEUR)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #treeViewTU
                [dataSource]="treeDataSourceTypeUtilisateur"
                dataStructure="plain"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                keyExpr="id"
                displayExpr="libelle"
                [selectByClick]="true"
                (onContentReady)="onTreeViewReady($event, TREE_BOX_VALUE_TYPE_UTILISATEUR)"
                (onItemSelectionChanged)="onTreeViewSelectionChanged($event, TREE_BOX_VALUE_TYPE_UTILISATEUR)"
              ></dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel">Motifs de non conformité</div>

      <div class="col-md-8">
        <div class="dx-field">
          <dx-drop-down-box
            [(value)]="treeBoxValueMotifNonConforme"
            valueExpr="id"
            displayExpr="libelle"
            placeholder="Sélectionner un ou des motif(s) de non conformité"
            [showClearButton]="true"
            [dataSource]="treeDataSourceMotifNonConforme"
            [disabled]="!canModify()"
            (onValueChanged)="onDropDownBoxValueChanged(TREE_BOX_VALUE_MOTIF_NON_CONFORMITE)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #treeViewMNC
                [dataSource]="treeDataSourceMotifNonConforme"
                dataStructure="plain"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                keyExpr="id"
                displayExpr="libelle"
                [selectByClick]="true"
                (onContentReady)="onTreeViewReady($event, TREE_BOX_VALUE_MOTIF_NON_CONFORMITE)"
                (onItemSelectionChanged)="onTreeViewSelectionChanged($event, TREE_BOX_VALUE_MOTIF_NON_CONFORMITE)"
              ></dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel">Tâche planifiable <span class="danger-color">*</span></div>

      <div class="col-md-8">
        <dx-switch [disabled]="tache?.isBasic" formControlName="isPlannable"></dx-switch>
      </div>
    </div>

    <div class="p-2 d-flex justify-content-end">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!canModify()"
        [class.disable]="!canModify()">
        <i class="fas fa-save"></i> Enregistrer
      </button>
      <button
        class="ml-3 btn btn-secondary"
        type="button"
        (click)="closeDialog()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </form>
</dx-popup>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
