import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {TableauDeBordSupplier} from './tableau-de-bord-resolver.service';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {uniqBy as _uniqBy} from 'lodash'
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/internal/operators';
import {UniteDeProductionDTO} from '../../core/dtos/unite-de-production-dto';
import {BoncfStatutDTO} from '../../core/dtos/boncf-statut-d-t-o';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {BoncfService} from '../../core/services/entities/boncf.service';
import {delay} from 'rxjs/operators';
import {AppellationDTO} from '../../core/dtos/appellations-dto';
import {GenericRequestSupplier} from '../../core/suppliers/generics/generic-request-supplier';
import {ObjectDTO} from '../../core/dtos/object-dto';
import {GenericHandler} from '../../core/services/generics/generic-handler';
import {utils as xlsxUtils, WorkBook as xlsxWorkBook, WorkSheet as xlsxWorkSheet, write as xlsxWrite} from 'xlsx';
import {ActivatedRoute} from '@angular/router';
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {ToastService} from "../../core/services/technique/toast.service";
import {CommandesService} from "../../core/services/gestion-commandes/commandes.service";
import {saveAs as fs_saveAs} from "file-saver";

@Component({
  selector: 'yo-tableau-de-bord',
  templateUrl: './tableau-de-bord.component.html',
  styleUrls: ['./tableau-de-bord.component.scss']
})
export class TableauDeBordComponent implements OnInit, OnDestroy{

  subscriptionRoute: Subscription;
  subForm: Subscription;
  subTableauDeBord: Subscription;

  formFilters:FormGroup;

  selectedDates: Date[] = [];
  unitesProduction: UniteDeProductionDTO[] = [];
  selectedUnitesProduction: UniteDeProductionDTO[] = [];
  statuts: BoncfStatutDTO[] = [];
  selectedStatut: BoncfStatutDTO;
  fournisseurs: UniteDeProduction__SecteurFournisseurDTO[] = [];
  selectedFournisseurs: UniteDeProduction__SecteurFournisseurDTO[] = [];
  selectedAppellations: AppellationDTO[] = [];

  localeFr: any;
  grs: GenericRequestSupplier;

  libelleAutresAppelations = 'Autres appellations';

  @Input() service: GenericHandler<ObjectDTO>;

  numberItemsOrderedPerPeriod: any;
  averageAmountItemsPerOrderAndPeriod: any;
  egalimIndicator: any;
  topItemsByWeight: any;
  topItemsByAmount: any;
  litigesNonConformite: any;
  seriesOptionsLitiges: any;
  amountsByAppellationPerPeriod: any;
  weightsByAppellationPerPeriod: any;
  numberOrdersWithoutDetailsPerPeriodByProductionUnit: any;
  numberOrdersWithDetailsPerPeriodByProductionUnit: any;
  numberOrdersWithDetailsPerPeriodByProductionUnitSelected: any;
  seriesOptionsNumberItemsWithoutDetails: any = [];
  seriesOptionsNumberItemsWithDetails: any = [];
  productionUnitSelectedForNumberItems: any;
  volumeItemsByProvider: any;

  amountItemsOrdersWithDetailsPerPeriodByProductionUnit: any;
  amountItemsOrdersWithDetailsPerPeriodByProductionUnitSelected: any;
  amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit: any;
  seriesOptionsAmountItemsWithoutDetails: any = [];
  seriesOptionsAmountItemsWithDetails: any = [];
  productionUnitSelectedForAmountItems: any;
  amountsItemsWeeklyPerFamilyAndPeriod: any;
  seriesOptionsAmountsItemsWeeklyPerFamilyAndPeriod: any = [];

  findNumberItemsOrderedPerProviderAndPeriod: any;
  findNumberItemsOrderedPerProviderAndPeriodSelected: any;
  seriesOptionsFindNumberItemsOrderedPerProviderAndPeriod: any = [];

  constructor(private route: ActivatedRoute,
              private boncfSvc: BoncfService,
              public commandesSvc: CommandesService,
              private utils: UtilsService,
              private toastSvc: ToastService) {}

  ngOnInit() {
    this.initRouteData();
    this.initFilterAndChartOption();
    this.changeValueSubcription();
    this.localeFr = DATEPICKER_FR;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subForm);
    this.utils.unsubscribe(this.subTableauDeBord);
  }

  initRouteData = (): void => {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { dashboardSupplier: TableauDeBordSupplier }) => {
        this.initForm();
        this.unitesProduction = data.dashboardSupplier.unitesProduction;
        this.statuts = data.dashboardSupplier.statuts;
      });
  };

  initForm = (): void => {
    const dateNow = new Date();
    this.formFilters = new FormGroup({
      dates:new FormControl([dateNow,dateNow],Validators.required),
      unitesDeProduction:new FormControl([],Validators.required),
      fournisseurs:new FormControl([],Validators.required),
      statut :new FormControl([],Validators.required),
    });
  };

  initFilterAndChartOption = (): void => {
    const date = new Date();
    this.selectedDates[0] = new Date(date.getFullYear(), date.getMonth(), 1);
    this.selectedDates[1] = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  };

  changeValueSubcription = (): void => {
    this.subForm = this.formFilters.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(response => {
      if (!this.utils.isCollectionNullOrEmpty(response.dates) &&
        !this.utils.isCollectionNullOrEmpty(response.fournisseurs) &&
        !this.utils.isCollectionNullOrEmpty(response.unitesDeProduction) &&
        !this.utils.isNullOrEmpty(response.statut)) {
        this.loadDataTableauDeBord(response.dates, response.unitesDeProduction, response.fournisseurs, response.statut.code);
      }
    });

  };
  loadDataTableauDeBord = (dates, udp, fournisseurs, statut_code) => {
    this.subTableauDeBord = this.boncfSvc.getDataTableauDeBord(dates, udp, fournisseurs, statut_code)
      .pipe(
        delay(0)
      )
      .subscribe((response: ResponseWrapper<any>) => {
        if (response.one) {
          const stats: any = response.one;
          this.numberItemsOrderedPerPeriod = stats?.numberItemsOrderedPerPeriod?.valuesByLabel;
          this.averageAmountItemsPerOrderAndPeriod = stats?.averageAmountItemsPerOrderAndPeriod?.values;
          this.egalimIndicator = stats?.egalimIndicator;
          this.topItemsByAmount = stats?.topItemsByAmount?.values;
          this.topItemsByWeight = stats?.topItemsByWeight?.values;
          this.litigesNonConformite = stats?.litigesNonConformite?.values;
          this.amountsByAppellationPerPeriod = stats?.amountsByAppellationPerPeriod?.valuesByLabel;
          this.weightsByAppellationPerPeriod = stats?.weightsByAppellationPerPeriod?.valuesByLabel;
          this.numberOrdersWithDetailsPerPeriodByProductionUnit = stats?.numberOrdersPerPeriod?.amountWithDetailsByProductionUnit;
          this.numberOrdersWithoutDetailsPerPeriodByProductionUnit = stats?.numberOrdersPerPeriod?.amountWithoutDetails?.values;
          this.amountItemsOrdersWithDetailsPerPeriodByProductionUnit = stats?.amountItemsWithoutDetailsPerPeriod?.amountWithDetailsByProductionUnit;
          this.amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit = stats?.amountItemsWithoutDetailsPerPeriod?.amountWithoutDetails?.values;
          this.amountsItemsWeeklyPerFamilyAndPeriod = stats?.amountsItemsWeeklyPerFamilyAndPeriod?.values;
          this.findNumberItemsOrderedPerProviderAndPeriod = stats?.findNumberItemsOrderedPerProviderAndPeriod;
          this.volumeItemsByProvider = stats?.volumeItemsByProvider?.valuesByLabel;

          if (this.amountsItemsWeeklyPerFamilyAndPeriod && this.amountsItemsWeeklyPerFamilyAndPeriod.length) {
            this.amountsItemsWeeklyPerFamilyAndPeriod.forEach(stat => {
              let keys = Object.keys(stat);
              keys = keys.filter(key => key !== 'numWeek');
              keys.forEach(key => {
                if (!this.seriesOptionsAmountsItemsWeeklyPerFamilyAndPeriod.find(x => x.valueField === key))
                  this.seriesOptionsAmountsItemsWeeklyPerFamilyAndPeriod.push({ type: 'stackedBar',  argumentField: 'numWeek', valueField: key, name: key });
              });
            });
          }

          if (this.litigesNonConformite && this.litigesNonConformite.length) {
            this.litigesNonConformite.forEach(stat => {
              let keys = Object.keys(stat);
              keys = keys.filter(key => key !== 'provider');
              this.seriesOptionsLitiges = keys.map(key => ({ type: 'bar',  argumentField: 'provider', valueField: key, name: key }) );
            });
            this.seriesOptionsLitiges = [...new Set(this.seriesOptionsLitiges)];
          }

          if (this.numberOrdersWithoutDetailsPerPeriodByProductionUnit && this.numberOrdersWithoutDetailsPerPeriodByProductionUnit.length) {
            this.numberOrdersWithoutDetailsPerPeriodByProductionUnit.forEach(stat => {
              let keys = Object.keys(stat);
              keys = keys.filter(key => key !== 'numWeek');
              keys.forEach(key => {
                if (!this.seriesOptionsNumberItemsWithoutDetails.find(x => x.valueField === key))
                  this.seriesOptionsNumberItemsWithoutDetails.push({ type: 'spline',  argumentField: 'numWeek', valueField: key, name: key });
              });
            });
          }

          if (this.amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit && this.amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit.length) {
            this.amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit.forEach(stat => {
              let keys = Object.keys(stat);
              keys = keys.filter(key => key !== 'numWeek');
              keys.forEach(key => {
                if (!this.seriesOptionsAmountItemsWithoutDetails.find(x => x.valueField === key))
                  this.seriesOptionsAmountItemsWithoutDetails.push({ type: 'spline',  argumentField: 'numWeek', valueField: key, name: key });
              });
            });
          }

        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.INFO, `Aucune donnée visible pour la sélection`);
        }
      });
  }

  displayDetailsAmountsItems = ($event): void => {
    const target: any = $event.target;
    const currentWeekData: any = target.data;
    const currentValue: any = target.initialValue;
    const valueSelectedIdx = Object.values(currentWeekData).indexOf(currentValue);
    const productionUnitSelected = Object.keys(currentWeekData)[valueSelectedIdx];

    if (this.amountItemsOrdersWithDetailsPerPeriodByProductionUnit) {
      const stats = this.amountItemsOrdersWithDetailsPerPeriodByProductionUnit[productionUnitSelected];
      if (stats && stats.values) {
        this.productionUnitSelectedForAmountItems = productionUnitSelected;
        this.amountItemsOrdersWithDetailsPerPeriodByProductionUnitSelected = stats.values;
        stats.values.forEach(stat => {
          let keys = Object.keys(stat);
          keys = keys.filter(key => key !== 'numWeek');
          keys.forEach(key => {
            if (!this.seriesOptionsAmountItemsWithDetails.find(x => x.valueField === key))
              this.seriesOptionsAmountItemsWithDetails.push({ type: 'spline',  argumentField: 'numWeek', valueField: key, name: key });
          });
        });
      }
    }
  }

  exportDatasToExcelFile = (): void => {
    const formatedStartingDate = `${this.selectedDates[0].getFullYear()}-${this.selectedDates[0].getMonth() + 1}-${this.selectedDates[0].getDate()}`;
    const formatedEndDate = `${this.selectedDates[1].getFullYear()}-${this.selectedDates[1].getMonth() + 1}-${this.selectedDates[1].getDate()}`;
    this.commandesSvc.getCommandesDatasAsExcelExport(this.selectedDates[0], this.selectedDates[1])
      .subscribe(response => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs_saveAs(blob, `Statistiques-commandes-fournisseurs-${formatedStartingDate}-${formatedEndDate}-${Date.now()}.xlsx`);
    });
  }

  displayDetailsNumberItems = ($event): void => {
    const target: any = $event.target;
    const currentWeekData: any = target.data;
    const currentValue: any = target.initialValue;
    const valueSelectedIdx = Object.values(currentWeekData).indexOf(currentValue);
    const productionUnitSelected = Object.keys(currentWeekData)[valueSelectedIdx];

    if (this.numberOrdersWithDetailsPerPeriodByProductionUnit) {
      const stats = this.numberOrdersWithDetailsPerPeriodByProductionUnit[productionUnitSelected];
      if (stats && stats.values) {
        this.productionUnitSelectedForNumberItems = productionUnitSelected;
        this.numberOrdersWithDetailsPerPeriodByProductionUnitSelected = stats.values;
        stats.values.forEach(stat => {
          let keys = Object.keys(stat);
          keys = keys.filter(key => key !== 'numWeek');
          keys.forEach(key => {
            if (!this.seriesOptionsNumberItemsWithDetails.find(x => x.valueField === key))
              this.seriesOptionsNumberItemsWithDetails.push({ type: 'stackedBar',  argumentField: 'numWeek', valueField: key, name: key });
          });
        });
      }
    }
  }

  displayDetailsNumberItemsOrderedByProvider = ($event): void => {
    const target: any = $event.target;
    const currentNumWeek: any = target.argument;

    if (this.findNumberItemsOrderedPerProviderAndPeriod) {
      const stats = this.findNumberItemsOrderedPerProviderAndPeriod[currentNumWeek];
      if (stats && stats.values)
        this.findNumberItemsOrderedPerProviderAndPeriodSelected = stats.values;
    }
  }

  getImageTrophyTop3Path = (annotation, byAmount: boolean): string => {
    const name: string = annotation.argument;
    const data = byAmount ? this.topItemsByAmount.map(val => val.label) : this.topItemsByWeight.map(val => val.label);
    const idx: number = data.indexOf(name);
    if (data.length === 1) {
      return 'assets/images/podium/podium-1.png';
    } else if (data.length === 2) {
      if (idx === 0)
        return 'assets/images/podium/podium-2.png'; // La médaille d'argent est à gauche
      else
        return 'assets/images/podium/podium-1.png'; // La médaille d'or est à droite
    } else {
      if (idx === 0) {
        return 'assets/images/podium/podium-2.png'; // La médaille d'argent est à gauche
      } else if (idx === 1) {
        return 'assets/images/podium/podium-1.png'; // La médaille d'or est à droite
      } else {
        return 'assets/images/podium/podium-3.png'; // La médaille de bronze est à droite
      }
    }
  };

  customizeTooltipAmountStackBar = (args: any) => ({
    text: `${args.seriesName} ${args.valueText} €`,
  });

  customizeTooltipAmountItems = (args: any) => ({
    text: `${args.argumentText} ${args.valueText} €`,
  });

  customizeTooltip = (args: any) => ({
    text: `${args.seriesName} ${args.valueText} `,
  });

  customizeLabelAmountItems = (args: any) => `${args.value} €`;

  customizeLabelPie = (args: any) => `${args.valueText} (${args.percentText})`;

  customizeTooltipPie = (args: any) => ({
    text: `${args.argumentText} ${args.percentText}`,
  });

  customizeToolTipAmountItems = (args: any) => ({
    text: `${args.value} €`,
  });

  customizeLabelPieWithDevise = (args: any) => `${args.valueText} € (${args.percentText})`;

  customizeTooltipPieWithDevise = (args: any) => ({
    text: `${args.argumentText} € ${args.percentText}`,
  });

  changeUdp = (): void => {
    this.fournisseurs = [];

    if(!this.utils.isCollectionNullOrEmpty(this.selectedUnitesProduction)){
      for (let udp of this.selectedUnitesProduction) {

        if(!this.utils.isCollectionNullOrEmpty(udp.uniteDeProduction__secteurFournisseurList)){
          for (let udpSf of udp.uniteDeProduction__secteurFournisseurList) {
              this.fournisseurs.push(udpSf);
          }
        }
      }

      this.fournisseurs = _uniqBy(this.fournisseurs,'idSecteurFournisseur');

      let selectedFournisseursCopy = this.selectedFournisseurs;
      this.selectedFournisseurs = [];

      let cpt = 0;
      for (let select of selectedFournisseursCopy) {
        let i = 0;
        while (i < this.fournisseurs.length && this.fournisseurs[i].idSecteurFournisseur !== select.idSecteurFournisseur) {
          i++;
        }
        if (i < this.fournisseurs.length) {
          this.selectedFournisseurs.push(this.fournisseurs[i]);
        }
        cpt++;
      }
    }
  };

  /**
   * Temporaire car on doit supprimer le moindre traitement xls du front
   * @param data
   * @param filename
   */
  exportSimpleExcelCsv = (data, filename): void => {
    if (data && filename) {
      const sheetResult = data.map(value => {
        let tmp = {};
        this.utils.addPropertyListAndValueList(tmp, Object.keys(value), Object.values(value))
        return tmp;
      });
      const sheet: xlsxWorkSheet = xlsxUtils.json_to_sheet(sheetResult);
      const workbook: xlsxWorkBook = { Sheets: { 'data': sheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsxWrite(workbook, { bookType: 'xlsx', type: 'array' });
      this.utils.saveAsExcelFile(excelBuffer, `${filename}-${new Date().getTime()}`);
    }
  };

  getText = (item: any, value: string) => (item.index === 0) ? `Pourcentage de produits durables - ${value} % (${this.egalimIndicator.totalMontantProduitsDurablesPeriod} €)` : `Pourcentage de produits bio ${value} % (${this.egalimIndicator.totalMontantProduitsBioPeriod} €)`;

  customizeTooltipGaugeEgalim = (arg): any => ({
    text: this.getText(arg, arg.valueText),
  });

  customizeTextLabelGaugeEgalim: (arg) => string = arg => `${arg.valueText} %`;

  customizeTextGaugeEgalim = (arg): any => this.getText(arg.item, arg.text);

}
