import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MenuPlcSupplier, PlcWeek} from './menu-plc-resolver.service';
import * as moment from 'moment';
import {unitOfTime} from 'moment';
import {PointDeLivraisonDTO} from '../../core/dtos/point-de-livraison-d-t-o';
import {MenuItem, SelectItem} from 'primeng/api';
import {RepasService} from '../../core/services/entities/repas.service';
import {PointDeLivraisonService} from '../../core/services/entities/point-de-livraison.service';
import {MenusToolbarService} from '../../core/services/gestionmenus/menus-toolbar.service';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {PrintEffectifService, PrintEffectifSupplier} from '../../core/services/gestion-plc/print-effectif.service';
import {EffectifMode} from './constant/menu-plc.constant';
import {PrintCoutRevientPlatService} from '../../core/services/gestion-plc/print-cout-revient-plat.service';
import {Menu} from "primeng/menu";
import {RepasDTO} from "../../core/dtos/repas-dto";
import {ContratMenuConviveRepasPlcDateDTO} from "../../core/dtos/cmcr-plc-date-dto";
import {MSG_KEY, MSG_SEVERITY, TYPE_EFFECTIF_IDS} from "../../core/constants";
import {CmcrPlcDateService} from "../../core/services/entities/cmcr-plc-date.service";
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-menu-plc',
  templateUrl: './menu-plc.component.html',
  styleUrls: ['./menu-plc.component.scss']
})
export class MenuPlcComponent implements OnInit, OnDestroy {
  get openDialogDuplicateEffectivesPrevisionnals(): () => void {
    return this._openDialogDuplicateEffectivesPrevisionnals;
  }

  set openDialogDuplicateEffectivesPrevisionnals(value: () => void) {
    this._openDialogDuplicateEffectivesPrevisionnals = value;
  }

  subMenuPlc: Subscription;
  subMonthChange: Subscription;
  subHaveDataToPrint: Subscription;

  mplc: MenuPlcSupplier;
  localeFr;
  week: Week;
  modes: SelectItem[]=[
    {label: 'Prévision', value: EffectifMode.Prevision},
    {label: 'Fabrication', value: EffectifMode.Fabrication},
    {label: 'Facturation', value: EffectifMode.Facturation},
  ];

  // point de livraison sélectionné
  selectedPlc: PointDeLivraisonDTO;
  // mode d'effectif sélectionné
  selectedMode: number = EffectifMode.Prevision;
  // date sélectionnée
  selectedDate: Date = new Date();

  plcWeek: PlcWeek;

  datesCreationMenus: Date[];
  datesSaisieEffectifs: Date[];
  disabledDays: number[];
  dateMin: Date;


  @ViewChild('printMenu') printMenu: Menu;


  itemCr = {
    icon: 'far fa-file-pdf',
    label: 'Calcul du coût de revient d\'un plat',
    command: () => this.openDialogPrintCourRevientPlat(),
  };

  itemEffectifs = {
    icon: 'far fa-file-excel',
    label: `Effectifs`,
    command: () => this.openDialogPrintEffectifMenu(),
    disabled: true
  };

  itemEffectifsTotaux = {
    icon: 'far fa-file-excel',
    label: `Effectifs totaux`,
    command: () => this.openDialogPrintEffectifMenuTotaux()
  };

  itemEffectifsDetails = {
    icon: 'far fa-file-excel',
    label: `Effectifs avec détail des menus`,
    command: () => this.openDialogPrintEffectifDetailsMenu(),
    disabled: true
  };

  itemsPrintMenu: MenuItem[] = [
    this.itemCr,
    this.itemEffectifs,
    this.itemEffectifsTotaux,
    this.itemEffectifsDetails
  ];

  popupDuplicateEffectivesVisible: boolean = false;
  duplicateToManufacturing: boolean = false;
  duplicateToBilling: boolean = false;

  constructor(public utils: UtilsService, private route: ActivatedRoute,
              private repasSvc: RepasService,
              private cd: ChangeDetectorRef,
              private menusToolbarSvc: MenusToolbarService,
              private plcSvc: PointDeLivraisonService,
              private printEffectifService: PrintEffectifService,
              private printCrpService: PrintCoutRevientPlatService,
              private cmcrPlcDateSvc: CmcrPlcDateService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {

    this.updateSelectedDate();
    // this.initItemMenuAndDateSetting();

    // a t on des données pour imprimer
    this.initHaveDataToPrintSubscription();

    // afficher les dates en cours de saisie ou en cours de creation menu
    this.menuPlcMonthChangeSubscription();

    // charger le menu plc
    this.menuPlcSubscription();

  }

  menuPlcSubscription = () => {
    this.subMenuPlc = this.route.data
      .subscribe((data: { menuPlcSupplier: MenuPlcSupplier }) => {

        this.mplc = data.menuPlcSupplier;

        this.selectedDate = new Date();
      });
  };

  menuPlcMonthChangeSubscription = () => {

    this.subMonthChange = this.plcSvc.menuPlcMonthChange$
      .pipe(
        debounceTime(100),
        switchMap(event => this.plcSvc.getMonthDates(event, this.selectedDate, this.selectedPlc, 2)),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {
        if (response) {
          this.initMenuPlc(response);
        }
      });
  };

  private _openDialogDuplicateEffectivesPrevisionnals = (): void => {
    this.popupDuplicateEffectivesVisible = true;
  }

  closeDialogDuplicateEffectivesPrevisionnals = (): void => {
    this.duplicateToManufacturing = false;
    this.duplicateToBilling = false;
    this.popupDuplicateEffectivesVisible = false;
  }

  duplicateEffectivesPrevisionnals = (): void => {
    if (this.plcWeek && this.plcWeek.repasList) {
      // La config d'Angular ne me permet pas d'utiliser le flatMap
      const typesEffectifsSelected: number[] = [1];
      if (this.duplicateToManufacturing) typesEffectifsSelected.push(TYPE_EFFECTIF_IDS.fabrication);
      if (this.duplicateToBilling) typesEffectifsSelected.push(TYPE_EFFECTIF_IDS.facturation);
      const cellsToDuplicate: ContratMenuConviveRepasPlcDateDTO[] = this.plcWeek.repasList.reduce((results: ContratMenuConviveRepasPlcDateDTO[], item) => {
          if (item.cells)
            results.push(...item.cells);
          return results;
        }, []);

      this.cmcrPlcDateSvc.duplicateEffectivesRepasPlc(cellsToDuplicate, typesEffectifsSelected)
                        .subscribe(() => {
                          this.closeDialogDuplicateEffectivesPrevisionnals();
                          this.changePlc(this.selectedPlc);
                          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Effectif(s) dupliqué(s) avec succès`);
                        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez sélectionner un point de livraison ayant un menu existant pour une période donnée`);
    }
  }

  ngOnDestroy = (): void => {
    this.utils.unsubscribe(this.subMenuPlc);
    this.utils.unsubscribe(this.subMonthChange);
    this.utils.unsubscribe(this.subHaveDataToPrint);
  };

  updateSelectedDate = () => {

    this.selectedDate = moment(this.selectedDate).startOf('isoweek' as unitOfTime.StartOf).toDate();

    this.week = {
      weekInYear: moment(this.selectedDate).isoWeek(),
      weekMonday: moment(this.selectedDate).startOf('isoweek' as unitOfTime.StartOf).toDate(),
      weekSunday: moment(this.selectedDate).endOf('isoweek' as unitOfTime.StartOf).toDate(),
      weekYear: moment(this.selectedDate).year()
    };

  };

  changeWeek = ($event: any) => {
    this.updateSelectedDate();
    this.changePlc(undefined);
  };

  changePlc = ($event: any) => {

    this.mplc.repasList = [];
    this.plcSvc.announcePlcWeek(undefined);
    this.plcSvc.announcePlcRepas(undefined);
    this.plcSvc.announceMenuPlcMonthChange(null);
    this.plcSvc.announceTypeEffectif(this.selectedMode);
    this.printEffectifService.announceHaveDataToPrint(false);

    if (!this.utils.isNullOrEmpty(this.selectedPlc)) {

      this.plcSvc.loadPlcWeek(this.week.weekMonday, this.week.weekSunday, this.selectedPlc).subscribe(response => {


        if (!this.utils.isNullOrEmpty(response)) {

          this.plcWeek = response.one;
          this.plcSvc.announcePlcWeek(this.plcWeek);
          this.plcSvc.announceTypeEffectif(this.selectedMode);
          this.printEffectifService.announceHaveDataToPrint(!this.utils.isNullOrEmpty(this.plcWeek));
        }
      });
    }
  };


  changeMode = ($event: any) => {
    this.plcSvc.announceTypeEffectif(this.selectedMode);
  };


  getWeekLabel = (week: Week) => 'S' + moment(week.weekMonday).isoWeek() + ' ' + this.utils.getFrenchDateDDMMYYYY(moment(week.weekMonday)) + ' au ' + this.utils.getFrenchDateDDMMYYYY(moment(week.weekSunday));


  getMonthDates = ($event: any) => {

    this.plcSvc.announceMenuPlcMonthChange($event);
  };

  openDialogPrintCourRevientPlat = () => {
    this.printCrpService.announceDisplayDialogPrintCoutRevientPlat(true);
  };

  openDialogPrintEffectifMenu = () => {

    const pes = new PrintEffectifSupplier(this.selectedPlc, this.selectedDate, this.plcWeek, true, false);
    this.printEffectifService.announceDisplayDialogPrintEffectif(pes);
  };

  openDialogPrintEffectifDetailsMenu = () => {
    const pes = new PrintEffectifSupplier(this.selectedPlc, this.selectedDate, this.plcWeek, true, true);
    this.printEffectifService.announceDisplayDialogPrintEffectif(pes);
  };

  openDialogPrintEffectifMenuTotaux = (): void => {
    this.printEffectifService.announceDisplayDialogPrintEffectifTotaux({plc: this.selectedPlc, dates: this.selectedDate});
  }

  initHaveDataToPrintSubscription = () => {
    this.subHaveDataToPrint = this.printEffectifService.haveDataToPrint$.subscribe((hasData: boolean) => {

      this.itemEffectifs.disabled = !hasData;
      this.itemEffectifsDetails.disabled = !hasData;

      this.itemsPrintMenu = [this.itemCr,this.itemEffectifs, this.itemEffectifsTotaux, this.itemEffectifsDetails];
    });
  };


  initMenuPlc = (response: any) => {
    const joursDisponibles = response.additionalProperties['joursDisponibles'];
    this.disabledDays = this.utils.getDisabledDays(joursDisponibles);

    this.datesSaisieEffectifs = response.additionalProperties['datesSaisieEffectifs'];
    this.datesCreationMenus = response.additionalProperties['datesCreationMenus'];
    this.dateMin = response.additionalProperties['dateMin'];

    this.dateMin = this.utils.convertNumberDateToDate(this.dateMin);

    if (!this.utils.isCollectionNullOrEmpty(this.datesSaisieEffectifs)) {
      this.datesSaisieEffectifs = this.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
    }

    if (!this.utils.isCollectionNullOrEmpty(this.datesCreationMenus)) {
      this.datesCreationMenus = this.datesCreationMenus.map(item => this.utils.convertNumberDateToDate(item));
    }
  };

  getRepasList = (plcWeek: PlcWeek): RepasDTO[] => {
    let repasList = [];
    if (
      !this.utils.isNullOrEmpty(plcWeek)
      && !this.utils.isCollectionNullOrEmpty(plcWeek.repasList)) {

      repasList = plcWeek.repasList.map(item => item.repas);

    }

    return repasList;
  };
}

interface Week {
  weekInYear: number;
  weekMonday: Date;
  weekSunday: Date;
  weekYear: number;
}
