import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {PlcRepasWeek, PlcWeek, PlcWeekDetails} from '../../../gestion-plc/menu-plc/menu-plc-resolver.service';
import {PointDeLivraisonDTO} from '../../dtos/point-de-livraison-d-t-o';
import {RepasDTO} from '../../dtos/repas-dto';
import * as moment from 'moment';
import {unitOfTime} from 'moment';
import {
  utils as xlsxUtils,
  WorkBook as xlsxWorkBook,
  WorkSheet as xlsxWorkSheet,
  writeFile as xlsxWriteFile
} from 'xlsx';
import {find, includes} from 'lodash';
import {EffectifMode} from '../../../gestion-plc/menu-plc/constant/menu-plc.constant';
import {UtilsService} from '../../utils/utils.service';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../dtos/contratmenuconvive__contrainte-alim-dto';
import {ContratMenuConviveRepasPlcDateDTO} from '../../dtos/cmcr-plc-date-dto';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {MSG_KEY, MSG_SEVERITY} from "../../constants";
import {ToastService} from "../technique/toast.service";
import {HttpService} from "../technique/http.service";

export const URL_GET_WEEK_PLC_DETAIL = `dolrest/gestion-clients/point-de-livraison/semaine/details`;
export const URL_GET_EFFECTIVES_TO_TOTAL = `dolrest/gestion-clients/effectifs-totaux`;

@Injectable()
export class PrintEffectifService {

  private colSpanTab: number[] = [];
  private colSpanInit: number = 0;

  private subjectDisplayDialogPrintEffectif = new Subject<PrintEffectifSupplier>();
  public displayDialogPrintEffectif$ = this.subjectDisplayDialogPrintEffectif.asObservable();

  private subjectDisplayDialogPrintEffectivesToTotal = new Subject<any>();
  public displayDialogPrintEffectivesToTotal$ = this.subjectDisplayDialogPrintEffectivesToTotal.asObservable();

  private subjectHaveDataToPrint = new Subject();
  public haveDataToPrint$ = this.subjectHaveDataToPrint.asObservable();

  constructor(private utilsService: UtilsService, private http: HttpClient, private httpSvc: HttpService, private toastSvc: ToastService) {}

  public announceDisplayDialogPrintEffectif(pes: PrintEffectifSupplier) {
    this.subjectDisplayDialogPrintEffectif.next(pes);
  }

  public announceHaveDataToPrint(haveDataToPrint: boolean) {
    this.subjectHaveDataToPrint.next(haveDataToPrint);
  }


  public exportExcel(plcRepasWeek: PlcRepasWeek, startDate: Date, selectedPlc: PointDeLivraisonDTO, modeIdList: number[], details: boolean, regimeIdList: number[]) {
    if (details) {
      this.printEffectifDetails(plcRepasWeek, startDate, selectedPlc, modeIdList, regimeIdList);
    } else {
      this.printEffectif(plcRepasWeek, startDate, selectedPlc, modeIdList, regimeIdList);
    }
  }

  private printEffectif(plcRepasWeek: PlcRepasWeek, selectedDate: Date, selectedPlc: PointDeLivraisonDTO, modeIdList: number[], regimeIdList: number[]) {

    const selectedRepas = plcRepasWeek.repas;

    const titleDate: string = this.getTitleDate(selectedDate);
    const titlePlc: string = this.getTitlePlc(selectedPlc, selectedRepas);

    //creation du fichier Excel
    const fileName = `${selectedRepas.libelle.toLowerCase()}_cout_matiere_` +
      moment(this.getWeekMonday(selectedDate)).format('DD-MM-YYYY').toString() + '_' +
      moment(this.getWeekSunday(selectedDate)).format('DD-MM-YYYY').toString() + '.xlsx';

    const wb: xlsxWorkBook = xlsxUtils.book_new();

    //creation du sheet Prevision
    if (includes(modeIdList, EffectifMode.Prevision)) {
      const previsionJson: any[] = this.getSheet(plcRepasWeek, EffectifMode.Prevision, titleDate, titlePlc, regimeIdList);

      const sheetPrevision: xlsxWorkSheet = xlsxUtils.json_to_sheet(previsionJson, {skipHeader: true});
      sheetPrevision['!merges'] = this.getCellToMerge();
      sheetPrevision['!cols'] = [{wch: 30}, {wch: 20}];

      xlsxUtils.book_append_sheet(wb, sheetPrevision, 'Prévision');
    }

    //creation du sheet Fabrication
    if (includes(modeIdList, EffectifMode.Fabrication)) {
      const fabricationJson: any[] = this.getSheet(plcRepasWeek, EffectifMode.Fabrication, titleDate, titlePlc, regimeIdList);

      const sheetFabrication: xlsxWorkSheet = xlsxUtils.json_to_sheet(fabricationJson, {skipHeader: true});
      sheetFabrication['!merges'] = this.getCellToMerge();
      sheetFabrication['!cols'] = [{wch: 30}, {wch: 20}];

      xlsxUtils.book_append_sheet(wb, sheetFabrication, 'Fabrication');
    }

    //creation du sheet Facturation
    if (includes(modeIdList, EffectifMode.Facturation)) {

      const facturationJson: any[] = this.getSheet(plcRepasWeek, EffectifMode.Facturation, titleDate, titlePlc, regimeIdList);

      const sheetFacturation: xlsxWorkSheet = xlsxUtils.json_to_sheet(facturationJson, {skipHeader: true});
      sheetFacturation['!merges'] = this.getCellToMerge();
      sheetFacturation['!cols'] = [{wch: 30}, {wch: 20}];

      xlsxUtils.book_append_sheet(wb, sheetFacturation, 'Facturation');
    }

    xlsxWriteFile(wb, fileName);
  }


  private printEffectifDetails(plcRepasWeek: PlcRepasWeek, selectedDate: Date, selectedPlc: PointDeLivraisonDTO, modeIdList: number[], regimeIdList: number[]) {

    const selectedRepas = plcRepasWeek.repas;
    const weekMonday = moment(this.getWeekMonday(selectedDate))
    const weekSunday = moment(this.getWeekSunday(selectedDate));
    const dateDebut = this.utilsService.getYYYYMMDD(weekMonday);
    const dateFin = this.utilsService.getYYYYMMDD(weekSunday);

    const idPlc = selectedPlc ? selectedPlc.id + '' : '';
    const idRepas = selectedRepas ? selectedRepas.id + '' : '';

    let col3 = '';
    let col4 = '';
    let col5 = '';
    let cpt = 0;
    if (includes(modeIdList, EffectifMode.Prevision)) {
      col3 = 'Prev.';
      cpt++;
    }
    if (includes(modeIdList, EffectifMode.Fabrication)) {
      if (cpt === 1) {
        col4 = 'Fab.';
      } else {
        col3 = 'Fab.';
      }
      cpt++;
    }
    if (includes(modeIdList, EffectifMode.Facturation)) {
      if (cpt === 2) {
        col5 = 'Fac.';
      } else if (cpt === 1) {
        col4 = 'Fac.';
      } else {
        col3 = 'Fac.';
      }
    }

    this.http.get(URL_GET_WEEK_PLC_DETAIL, {
      params: new HttpParams()
        .set('dateDebut', dateDebut)
        .set('dateFin', dateFin)
        .set('idPlc', idPlc)
        .set('idRepas', idRepas)

    })
      .pipe(
        catchError(error => this.utilsService.handleError(error))
      ).subscribe(response => {
      let plcWeekDetails: PlcWeekDetails[] = response.resultList;

      if (plcWeekDetails.length === 0) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Aucune donnée pour [${selectedPlc.libelle.toUpperCase()}] - [${selectedRepas.libelle.toLowerCase()}]`);
        return;
      }

      const offreAlimentaire: string = 'Offre alimentaire : ' + this.getOffreAlimentaire(selectedPlc);
      const wb: xlsxWorkBook = xlsxUtils.book_new();

      for (let tab of plcWeekDetails) {
        const titlePrestation = 'Prestation : ' + tab.cmcCa.cmcLibelle;
        const titleRegime = 'Régime : ' + tab.cmcCa.regimeLibelle;

        cpt = 0;

        if (regimeIdList && regimeIdList.length > 0) {
          while (cpt < regimeIdList.length && regimeIdList[cpt] !== tab.cmcCa.regimeId) {
            cpt++;
          }
        }

        if (!regimeIdList || regimeIdList.length === 0 || cpt < regimeIdList.length) {
          let jsons: any[] = [];

          jsons.push(
            {'col1': offreAlimentaire, 'col2': '', 'col3': '', 'col4': '', 'col5': ''});
          jsons.push(
            {'col1': titlePrestation, 'col2': '', 'col3': '', 'col4': '', 'col5': ''});
          jsons.push(
            {'col1': titleRegime, 'col2': '', 'col3': '', 'col4': '', 'col5': ''});
          jsons.push(
            {'col1': '', 'col2': '', 'col3': '', 'col4': '', 'col5': ''});
          jsons.push(
            {'col1': '', 'col2': '', 'col3': '', 'col4': '', 'col5': ''});

          jsons.push(
            {'col1': 'Date', 'col2': 'Repas', 'col3': 'Effectifs', 'col4': '', 'col5': ''});

          for (let repas of tab.repas) {
            if (repas.rows !== null && repas.rows.length > 0) {

              let first = true;
              for (let row of repas.rows) {
                if (first) {
                  jsons.push(
                    {
                      'col1': moment(row.dateMenu).format('DD/MM/YYYY'),
                      'col2': selectedRepas.libelle,
                      'col3': col3,
                      'col4': col4,
                      'col5': col5
                    });
                  first = false;
                }
                if (row.plat !== null) {
                  cpt = 0;
                  let json = {
                    'col1': '',
                    'col2': row.plat.produitDeclinaison.libelle,
                    'col3': '',
                    'col4': '',
                    'col5': ''
                  }
                  if (includes(modeIdList, EffectifMode.Prevision)) {
                    json['col3'] = '' + row.plat.effectifPrevisionnel;
                    cpt++;
                  }
                  if (includes(modeIdList, EffectifMode.Fabrication)) {
                    if (cpt === 1) {
                      json['col4'] = '' + row.plat.effectifFabrication;
                    } else {
                      json['col3'] = '' + row.plat.effectifFabrication;
                    }
                    cpt++;
                  }
                  if (includes(modeIdList, EffectifMode.Facturation)) {
                    if (cpt === 2) {
                      json['col5'] = '' + row.plat.effectifFacture;
                    } else if (cpt === 1) {
                      json['col4'] = '' + row.plat.effectifFacture;
                    } else {
                      json['col3'] = '' + row.plat.effectifFacture;
                    }
                  }
                  jsons.push(json);
                }
              }

              jsons.push(
                {'col1': '', 'col2': '', 'col3': '', 'col4': '', 'col5': ''});
            }
          }


          const worksheet: xlsxWorkSheet = xlsxUtils.json_to_sheet(jsons, {skipHeader: true});
          worksheet["!merges"] = [{s: {c: 0, r: 0}, e: {c: 3, r: 0}},
            {s: {c: 0, r: 1}, e: {c: 3, r: 1}},
            {s: {c: 0, r: 2}, e: {c: 3, r: 2}},
            {s: {c: 2, r: 5}, e: {c: 4, r: 5}}];

          let sheetName = `${tab.cmcCa.cmcLibelle} - ${tab.cmcCa.regimeLibelle}`;
          if (sheetName.length > 31) {
            sheetName = sheetName.substr(0, 31);
          }

          sheetName = sheetName.replace(/\//g,'_');
          sheetName = sheetName.replace('/','_');
          sheetName = sheetName.replace('?','');
          sheetName = sheetName.replace('*','');
          sheetName = sheetName.replace('.','_');

          xlsxUtils.book_append_sheet(wb, worksheet, sheetName);
        }
      }

      xlsxWriteFile(wb, `${selectedRepas.libelle.toLowerCase()}_import_effectif.xlsx`);

    });

  }

  private getTitleDate(selectedDate: Date): string {
    const weekMonday = moment(this.getWeekMonday(selectedDate)).format('DD/MM/YYYY');
    const weekSunday = moment(this.getWeekSunday(selectedDate)).format('DD/MM/YYYY');
    return 'Periode du ' + weekMonday.toString() + ' au ' + weekSunday.toString();
  }

  private getTitlePlc(selectedPlc: PointDeLivraisonDTO, selectedRepas: RepasDTO) {
    return 'Point de livraison : ' + selectedPlc.libelle + ', Repas : ' + selectedRepas.libelle;
  }

  private getWeekMonday(selectedDate: Date) {
    return moment(selectedDate).startOf('isoweek' as unitOfTime.StartOf).toDate();
  }

  private getWeekSunday(selectedDate: Date) {
    return moment(selectedDate).endOf('isoweek' as unitOfTime.StartOf).toDate();
  }

  private getSheet(plcRepasWeek: PlcRepasWeek, mode: number, titleDate: string, titlePlc: string, regimeIdList: number[]): any[] {

    const colNumber = plcRepasWeek.cols.length;
    const cols: string[] = this.getColumnList(colNumber);

    const effectifTab: string[][] = this.getEffectifTab(plcRepasWeek, mode, regimeIdList);

    // creation du sheet
    let sheetResult: any[] = [];
    sheetResult = this.createOneRow(cols, [titleDate], sheetResult);
    sheetResult = this.createOneRow(cols, [titlePlc], sheetResult);
    sheetResult = this.blankRow(cols, sheetResult);

    // les 3 lignes du dessus + le headers du tableau
    // premiere valeur du tableau 0
    this.colSpanInit = 3;

    effectifTab.forEach((rowTab: any[]) => {
      sheetResult = this.createOneRow(cols, rowTab, sheetResult);
    });

    return sheetResult;
  }

  private blankRow(cols: string[], sheetResult: any[]) {
    let result: any = {};

    this.utilsService.addPropertyList(result, cols);
    sheetResult.push(result);

    return sheetResult;
  }

  private createOneRow(cols: string[], values: any[], sheetResult: any[]) {
    let result: any = {};

    this.utilsService.addPropertyListAndValueList(result, cols, values);
    sheetResult.push(result);

    return sheetResult;
  }


  private getColumnList(colNumber: number) {

    let result: string[] = [];

    // + 2 pour les colonnes "prestations" et "régimes"
    for (let i = 0; i < colNumber + 2; i++) {
      const colName = 'col'.concat((i + 1).toString());
      result.push(colName);
    }
    return result;
  }

  private getEffectifTab(plcRepasWeek: PlcRepasWeek, mode: number, regimeIdList: number[]) {

    let result: string[][] = [];

    result.push(this.getHeadersColumnValueList(plcRepasWeek.cols));

    result = this.getTabEffectifValue(result, plcRepasWeek, mode, regimeIdList);

    return result;
  }

  private getHeadersColumnValueList(cols: Date[]) {
    let result: string[] = [];

    result.push('Prestations');
    result.push('Régimes');

    cols.forEach((date: Date) => {
      moment.locale('fr');
      result.push(moment(date).format('ddd D MMM'));
    });

    return result;
  }

  private getTabEffectifValue(result: string[][], plcRepasWeek: PlcRepasWeek, mode: number, regimeIdList: number[]) {
    this.colSpanTab = [];
    let cmcIdControl: number = 0;
    let firstLoop: boolean = true;
    let colSpanNumber: number = 0;

    plcRepasWeek.rows.forEach((cmcCa: ContratMenuConvive__ContrainteAlimDTO) => {

      let i = 0;
      if (regimeIdList && regimeIdList.length > 0) {
        while (i < regimeIdList.length && regimeIdList[i] !== cmcCa.regimeId) {
          i++;
        }
      }

      if (!regimeIdList || regimeIdList.length === 0 || i < regimeIdList.length) {
        let rowTab: any[] = [];

        // colspan  utilisé pour les cellules a merger
        if (cmcIdControl !== cmcCa.cmcId) {
          if (!firstLoop) {
            this.colSpanTab.push(colSpanNumber);
            colSpanNumber = 0;
          }

          cmcIdControl = cmcCa.cmcId;
          firstLoop = false;
          // prestation libelle
          rowTab.push(cmcCa.cmcLibelle);
          colSpanNumber++;
        } else {
          //si la prestation même que la précédante
          rowTab.push(undefined);
          colSpanNumber++;
        }

        //régime libelle
        rowTab.push(cmcCa.regimeLibelle);

        plcRepasWeek.cols.forEach((col: Date) => {
          //valeurs tableau
          rowTab.push(this.getCell(col, cmcCa.id, plcRepasWeek.cells, mode));
        });

        result.push(rowTab);
      }
    });
    this.colSpanTab.push(colSpanNumber);
    return result;
  }

  private getCell(col: Date, cmcCaId: number, cells: ContratMenuConviveRepasPlcDateDTO[], mode: number): number {

    // récuperaion du regime qui match avec la date et le regimeId
    let cellMatched: ContratMenuConviveRepasPlcDateDTO = find(cells, (cell: ContratMenuConviveRepasPlcDateDTO) => {
      if (!this.utilsService.isNullOrEmpty(cell)) {
        return cell.dateRepas === col && cell.cmcCaId === cmcCaId
      } else {
        return null;
      }
    });

    // return l'effectif en fonction du mode passé si pas de régime alors undefined
    if (!this.utilsService.isNullOrEmpty(cellMatched)) {
      switch (mode) {
        case EffectifMode.Prevision:
          return cellMatched.effectifPrevisionnel;
        case  EffectifMode.Fabrication:
          return cellMatched.effectifFabrication;
        case  EffectifMode.Facturation:
          return cellMatched.effectifFacture;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }


  getCellToMerge() {
    let result: any[] = [];
    let cellStartMerge = this.colSpanInit;

    // merge pour le titre
    result.push({s: {c: 0, r: 0}, e: {c: 2, r: 0}});

    // merge poour les prestations redondantes
    this.colSpanTab.forEach((colSpan: number) => {
      if (colSpan > 1) {
        result.push({s: {c: 0, r: cellStartMerge + 1}, e: {c: 0, r: cellStartMerge + colSpan}});
      }
      cellStartMerge += colSpan;
    });

    return result;
  }

  private getOffreAlimentaire(selectedPlc: PointDeLivraisonDTO) {
    if (selectedPlc && selectedPlc.client) {
      return selectedPlc.client.libelle;
    }

    return '';
  }

  announceDisplayDialogPrintEffectifTotaux = (params: any): void => {
    this.subjectDisplayDialogPrintEffectivesToTotal.next(params);
  }

  printEffectivesToTotal = (filters: any): Observable<any> => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    console.log('args', args);
    return this.httpSvc.get(`${URL_GET_EFFECTIVES_TO_TOTAL}?${args}`, null, 'blob');
  }

}


export class PrintEffectifSupplier {

  selectedPlc: PointDeLivraisonDTO;
  selectedDate: Date;

  plcWeek: PlcWeek;
  display: boolean;
  details: boolean;


  constructor(selectedPlc: PointDeLivraisonDTO, selectedDate: Date, plcWeek: PlcWeek, display: boolean, details: boolean) {
    this.selectedPlc = selectedPlc;
    this.selectedDate = selectedDate;
    this.plcWeek = plcWeek;
    this.display = display;
    this.details = details;
  }
}
