import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  FP_ROUTES,
  HAS_FACTURATION,
  HAS_GESTIONCOMMANDES,
  HAS_ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD,
  HAS_STOCKS
} from "../core/constants";
import {GcfRootComponent} from "./gcf-root/gcf-root.component";
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {BcAValiderComponent} from "./bc/bc-a-valider/bc-a-valider.component";
import {BcPropositionsComponent} from "./bc/bc-propositions/bc-propositions.component";
import {BcFacturesComponent} from "./bc/bc-factures/bc-factures.component";
import {TableauDeBordComponent} from "./tableau-de-bord/tableau-de-bord.component";
import {TableauDeBordResolverService} from "./tableau-de-bord/tableau-de-bord-resolver.service";
import {BcComponent} from "./bc/bc.component";
import {BcReceptionsComponent} from "./bc/bc-receptions/bc-receptions.component";
import {BcAReceptionnerComponent} from "./bc/bc-a-receptionner/bc-a-receptionner.component";
import {BcRefuseesComponent} from "./bc/bc-refusees/bc-refusees.component";

const routes: Routes = [
  {
    path: FP_ROUTES.GESTION_COMMANDES_FOURNISSEURS,
    component: GcfRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers: 'always',
    data: HAS_GESTIONCOMMANDES,
    children: [
      {
        path:'bc',
        component:BcComponent,
        data: HAS_GESTIONCOMMANDES,
        runGuardsAndResolvers: 'always',
        children:[
          {
            path:'tableau-de-bord',
            component:TableauDeBordComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD,
            resolve:{
              dashboardSupplier:TableauDeBordResolverService
            }
          },
          {
            path:'propositions',
            component:BcPropositionsComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_GESTIONCOMMANDES
          },
          {
            path:'refusees',
            component:BcRefuseesComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_GESTIONCOMMANDES
          },
          {
            path:'a-valider',
            component:BcAValiderComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_GESTIONCOMMANDES

          },
          {
            path:'a-receptionner',
            component:BcAReceptionnerComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_STOCKS
          },
          {
            path:'receptions',
            component:BcReceptionsComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_STOCKS
          },
          {
            path:'factures',
            component:BcFacturesComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_FACTURATION
          }
        ]
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionCommandesFournisseursRoutingModule {
}
