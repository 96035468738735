import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MENUS_SEARCH_MODES, SEARCH_PLATS_TYPE_DIALOG} from '../../core/constants';
import {
  DialogRecherchePlatsSupplier,
  MenusPlanning2Service
} from '../../core/services/gestionmenus/menus-planning2.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../core/utils/utils.service';
import {AppellationsService} from '../../core/services/entities/appellations.service';
import {TypesProduitService} from '../../core/services/entities/types-produit.service';
import {ProduitDeclinaisonDTO} from '../../core/dtos/produit-declinaison-dto';
import {FamilleProduitDTO} from '../../core/dtos/famille-produit-dto';
import {TypeProduitDTO} from '../../core/dtos/type-produit-dto';
import {AllergeneDTO} from '../../core/dtos/allergene-dto';
import {AppellationDTO} from '../../core/dtos/appellations-dto';
import {DeclinaisonDTO} from '../../core/dtos/declinaison-dto';
import {Sort} from '../../core/suppliers/generics/generic-request-supplier';
import {SearchSupplierWrapper} from '../../core/suppliers/wrappers/search-supplier-wrapper';
import {of, Subject, Subscription} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {SearchSupplier} from '../../core/suppliers/search-supplier';
import {concat as _concat} from 'lodash';
import * as moment from 'moment';
import {ObjectDTO} from '../../core/dtos/object-dto';
import {FamilleGemrcnDTO} from '../../core/dtos/famille-gemrcn-dto';
import {MenusSearchService} from '../../core/services/gestionmenus/menus-search.service';
import {MenusCompositionService} from '../../core/services/gestionmenus/menus-composition.service';
import {MenucompositionPlcService} from '../../core/services/entities/menucomposition-plc.service';
import {ProduitDeclinaisonService} from '../../core/services/entities/produit-declinaison.service';
import {ModeleNutritionnelDTO} from '../../core/dtos/modele-nutritionnel-dto';
import {SiteDTO} from "../../core/dtos/site-dto";
import {Auth2Service} from "../../core/services/security/auth2.service";
import CustomStore from "devextreme/data/custom_store";
import {DevextremeService} from "../../core/services/technique/devextreme.service";
import {
  DxDataGridComponent,
  DxDropDownBoxComponent,
  DxNumberBoxComponent,
  DxTagBoxComponent,
  DxTextBoxComponent,
  DxTreeViewComponent
} from "devextreme-angular";
import {FamillesProduitService} from "../../core/services/entities/familles-produit.service";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'yo-dialog-recherche-plats',
  templateUrl: './dialog-recherche-plats.component.html',
  styleUrls: ['./dialog-recherche-plats.component.scss']
})
export class DialogRecherchePlatsComponent implements OnInit, OnDestroy {

  subInit: Subscription;
  subPrefs: Subscription;
  subOpenDialog: Subscription;
  searchSupplier: DialogRecherchePlatsSupplier;

  searchMode: number;
  searchModes = MENUS_SEARCH_MODES;

  displayCart: boolean;

  produitsDeclinaisons: ProduitDeclinaisonDTO[] = [];
  famillesPlats: FamilleProduitDTO[] = [];
  famillesGemrcn: FamilleGemrcnDTO[] = [];
  famillesGemrcnFromCellSelected: FamilleGemrcnDTO[] = [];
  types: TypeProduitDTO[] = [];
  allergenes: AllergeneDTO[] = [];
  appellations: AppellationDTO[] = [];
  declinaisons: DeclinaisonDTO[] = [];
  sites: SiteDTO[] = [];
  planAlimentaire: ModeleNutritionnelDTO;
  numeroSemainePlanAlimentaire: number;
  totalElements = 0;

  // prix achat
  prixAchatMax: number;
  prixAchatMin: number;
  isPrixMaxSetByUser: boolean = false;

  // libelle du plat
  libelle: string;
  selectedFamillesPlats: number[] = [];
  selectedDeclinaisons: number[] = [];
  selectedTypesProduit: number[] = [];
  selectedFamillesGemrcn: number[] = [];
  selectedAppellations: number[] = [];
  selectedSites: number[] = [];
  selectedSitesToolTip: string = '';
  selectedFamillesGemrcnToolTip: string = '';

  produitsDeclinaisonsInCart: ProduitDeclinaisonDTO[] = [];

  subLibelleSearch: Subscription;
  subjectLibelleSearch: Subject<string> = new Subject<string>();
  libelleSearch$ = this.subjectLibelleSearch.asObservable();

  @ViewChild("grid") grid: DxDataGridComponent;
  @ViewChild("inputLibellePlat", { static: false }) dxInputLibellePlat: DxTextBoxComponent;
  @ViewChild("inputSites", {static: false })  dxInputSites: DxTagBoxComponent;
  @ViewChild("inputDeclinaisons", {static: false })  dxInputDeclinaisons: DxTagBoxComponent;
  @ViewChild("inputTypes", {static: false }) dxInputTypes: DxTagBoxComponent;
  @ViewChild("inputFamilleProduit", {static: false }) dxInputFamilleProduit : DxDropDownBoxComponent;
  @ViewChild("treeViewFamillePlat", {static: false }) dxInputFamillePlat : DxTreeViewComponent;
  @ViewChild("inputFamilleGemrcn", {static: false }) dxInputFamilleGemrcn: DxTagBoxComponent;
  @ViewChild("inputAppellations", {static: false }) dxInputAppellations: DxTagBoxComponent;
  @ViewChild("inputPrixMin", {static: false }) dxInputPrixMin: DxNumberBoxComponent;
  @ViewChild("inputPrixMax", {static: false }) dxInputPrixMax: DxNumberBoxComponent;

  dataSource: CustomStore;

  treeBoxValue: string[];
  treeDataSource: any;

  displayDialog: boolean = false;
  titleDialog: string = '';

  filterUsed = false;

  constructor(public cd: ChangeDetectorRef,
              public mp2Svc: MenusPlanning2Service,
              public gds: GenericDatagridService,
              public utils: UtilsService,
              public typeProduitSvc: TypesProduitService,
              public menusSearchSvc: MenusSearchService,
              public appellationsSvc: AppellationsService,
              public menusComposSvc: MenusCompositionService,
              private mcPlcSvc: MenucompositionPlcService,
              private auth2Svc: Auth2Service,
              public pdSvc: ProduitDeclinaisonService,
              private dxSvc: DevextremeService,
              private fpSvc: FamillesProduitService) {
  }

  ngOnInit() {
    this.subscriptionLibelleSearch();
    this.openDialogSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subInit);
    this.utils.unsubscribe(this.subPrefs);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subjectLibelleSearch);
  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);

        const ssWrapper = new SearchSupplierWrapper();
        ssWrapper.filtersMap['searchMode'] = new SearchSupplier(this.searchMode, undefined, null, null);
        ssWrapper.filtersMap['idContratMenuConviveDecoupage'] = new SearchSupplier(this.searchSupplier.cmcd.id, undefined, null, null);
        ssWrapper.filtersMap['jourRepas'] = new SearchSupplier(this.utils.getYYYYMMDD(moment(this.searchSupplier.dateMenu)), undefined, null, null);
        ssWrapper.filtersMap['searchMode'] = new SearchSupplier(this.searchMode, undefined, null, null);
        ssWrapper.filtersMap['idCmcContrainteAlim'] = new SearchSupplier(this.searchSupplier.cmcContrainteAlim.id, undefined, null, null);
        ssWrapper.filtersMap['idRegime'] = new SearchSupplier(this.searchSupplier.regimeAlimentaire.regimeId, undefined, null, null);
        ssWrapper.filtersMap['contratMenuConviveId'] = new SearchSupplier(this.searchSupplier.prestation.id, undefined, undefined, undefined);

        if (this.libelle)
          ssWrapper.filtersMap['libelle'] = new SearchSupplier(this.libelle, undefined, 'sortLibelle', 'string');

        if (sorts && sorts.length && sorts[0].dir && sorts[0].path) {
          ssWrapper.filtersMap['sortDir'] = new SearchSupplier(sorts[0].dir, undefined, sorts[0].path, null);
          ssWrapper.filtersMap['sortField'] = new SearchSupplier(sorts[0].path, undefined, sorts[0].path, null);
        }
        ssWrapper.filtersMap['page'] = new SearchSupplier(page);
        ssWrapper.filtersMap['size'] = new SearchSupplier(pageSize);

        const sites = this.selectedSites && this.selectedSites.length ? this.selectedSites : this.auth2Svc.utilisateur.sites.map(s => s.id);
        ssWrapper.filtersMap['idsSite'] = new SearchSupplier(null, sites, null, null);
        this.selectedSitesToolTip = sites.map(s => s.libelle).join(', ');

        const declinaisons = this.selectedDeclinaisons && this.selectedDeclinaisons.length ? this.selectedDeclinaisons : [];
        ssWrapper.filtersMap['idsDeclinaison'] = new SearchSupplier(undefined, declinaisons, null, null);

        const types = this.selectedTypesProduit && this.selectedTypesProduit.length ? this.selectedTypesProduit : [];
        ssWrapper.filtersMap['idsTypeProduit'] = new SearchSupplier(undefined, types, null, null);

        let famillesGemrcn = this.selectedFamillesGemrcn && this.selectedFamillesGemrcn.length ? this.selectedFamillesGemrcn : this.famillesGemrcnFromCellSelected.map(fp => fp.id);
        if (this.searchMode === 1)  famillesGemrcn = this.selectedFamillesGemrcn && this.selectedFamillesGemrcn.length ? this.selectedFamillesGemrcn : [];
        else if (this.searchMode === 2) famillesGemrcn = this.selectedFamillesGemrcn && this.selectedFamillesGemrcn.length ? this.selectedFamillesGemrcn : [];

        let tableMustBeEmpty: boolean = false;
        if (this.searchMode === 3 && (!this.famillesGemrcnFromCellSelected || !this.famillesGemrcnFromCellSelected.length))
          tableMustBeEmpty = true;

        ssWrapper.filtersMap['idsFamilleGemrcn'] = new SearchSupplier(undefined, famillesGemrcn, null, null);

        const appellations = this.selectedAppellations && this.selectedAppellations.length ? this.selectedAppellations : [];
        ssWrapper.filtersMap['idsAppellation'] = new SearchSupplier(undefined, appellations, null, null);

        const famillesPlats = this.selectedFamillesPlats && this.selectedFamillesPlats.length ? this.selectedFamillesPlats : [];
        ssWrapper.filtersMap['idsFamilleProduit'] = new SearchSupplier(null, famillesPlats, null, null);

        if (this.prixAchatMin !== null && this.prixAchatMin !== undefined)
          ssWrapper.filtersMap['prixMin'] = new SearchSupplier(this.prixAchatMin, undefined, undefined, undefined);
        if (this.prixAchatMax !== null && this.prixAchatMax !== undefined)
          ssWrapper.filtersMap['prixMax'] = new SearchSupplier(this.prixAchatMax, undefined, undefined, undefined);

        return this.menusSearchSvc.launchSearch(ssWrapper)
          .toPromise().then(response => {
          this.produitsDeclinaisons = response.resultList;

          this.totalElements = response.totalElements;

          if (!this.isPrixMaxSetByUser)
            this.prixAchatMax = response.additionalProperties["prixMaxRepas"];

            setTimeout(() => {
              if (this.dxInputLibellePlat && this.dxInputLibellePlat.instance) {
                this.dxInputLibellePlat.instance.focus();
              }
            }, 500);

          return {
            data: tableMustBeEmpty ? [] : response.resultList,
            totalCount: tableMustBeEmpty ? 0 : response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }

  onChangeSite = ($event): void => {
    this.selectedSites = $event.value;
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  onChangeDeclinaison = ($event): void => {
    this.selectedDeclinaisons = $event.value;
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  onChangeType = ($event): void => {
    this.selectedTypesProduit = $event.value;
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  onChangeFamilleGemrcn = ($event): void => {
    this.selectedFamillesGemrcn = $event.value;
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  onChangeAppellationGemrcn = ($event): void => {
    this.selectedAppellations = $event.value;
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  announceLibelleSearch = (e: any): void => {
    this.subjectLibelleSearch.next(e.value);
  }

  subscriptionLibelleSearch = (): void => {
    this.subLibelleSearch = this.libelleSearch$
      .pipe(debounceTime(500))
      .subscribe(searchValue => {
        this.libelle = searchValue;
        this.filterUsed = true;
        this.grid.instance.refresh();
    });
  }

  announcePrixMinSearch = ($event): void => {
    this.prixAchatMin = $event.value;
    this.checkPrixAchatMin();
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  announcePrixMaxSearch = ($event): void => {
    this.prixAchatMax = $event.value;
    this.checkPrixAchatMax();
    this.isPrixMaxSetByUser = true;
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  onChangeParentFamilleProduitSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.selectedFamillesPlats = this.getIdFamilleProduitListSelected(selectedNodeKeys);
    this.filterUsed = true;
    this.grid.instance.refresh();
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.famillesPlats.forEach(fp => {
        if (!this.utils.isNullOrEmpty(fp.parent) && fp.parent.id === id) {
          result.push(fp.id);
          result = _concat([...result], this.reflexionSearchId([...this.famillesPlats], fp.id))
        }
      })
    });

    result = _concat([...result], idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (!this.utils.isNullOrEmpty(item.parent) && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = _concat([...idResultList], this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

  /**
   * Réinitialiser les champs
   */
  reset = (): void => {
    this.resetFieldSearch();
    this.eraseCart();
  };

  resetFieldSearch = (): void => {
    this.sites = [];
    this.produitsDeclinaisons = [];
    this.produitsDeclinaisonsInCart = [];
    this.displayDialog = true;
    this.libelle = '';
    this.selectedFamillesPlats = [];
    this.selectedDeclinaisons = [];
    this.selectedTypesProduit = [];
    this.selectedFamillesGemrcn = [];
    this.selectedFamillesGemrcnToolTip = '';
    this.selectedAppellations = [];
    this.selectedSites = [];
    this.selectedSitesToolTip = '';
    this.dxInputLibellePlat.instance.reset();
    this.dxInputSites.instance.reset();
    this.dxInputAppellations.instance.reset();
    this.dxInputFamilleProduit.instance.reset();
    this.dxInputFamilleGemrcn.instance.reset();
    this.dxInputDeclinaisons.instance.reset();
    this.dxInputPrixMax.instance.reset();
    this.dxInputPrixMin.instance.reset();
    this.dxInputTypes.instance.reset();
    this.grid.instance.state(null);
  }

  updateCart = (plat: ProduitDeclinaisonDTO): void => {
    this.produitsDeclinaisonsInCart.push(plat);
  }

  isPresentInCart = (plat: ProduitDeclinaisonDTO): boolean => {
    const platFound: ProduitDeclinaisonDTO = this.produitsDeclinaisonsInCart.find(p => p.id === plat.id);
    return platFound !== null && platFound !== undefined;
  }

  refreshLazyLoadingSelectedRow = (): void => {
    this.grid.instance.selectRows(this.produitsDeclinaisonsInCart.map(pd => pd.id), true);
  }

  onHideSearchDialog = ($event): void => {
    this.displayDialog = false;
    this.isPrixMaxSetByUser = false;
    this.prixAchatMin = 0;
  };

  showDialogMaximized = ($event, dialog): void => {
    dialog.maximize();
  };

  openDialogSubscription = (): void => {
    this.subOpenDialog = this.mp2Svc.inputRecherchePlats$.pipe(
      switchMap((r: DialogRecherchePlatsSupplier) => {
        this.searchSupplier = r;
        this.searchMode = this.searchSupplier.searchMode;
        this.famillesGemrcnFromCellSelected = this.searchSupplier && this.searchSupplier.famillesGemRcn ? this.searchSupplier.famillesGemRcn : [];
        this.selectedFamillesGemrcn = this.searchSupplier && this.searchSupplier.famillesGemRcn ? this.searchSupplier.famillesGemRcn.map(f => f.id) : [];
        return this.mp2Svc.getFiltersDialogSearchPlats(r);
      }),
      switchMap((response: any) => {
            if (response) {
              const filtersBackEnd: any = response.one;
              this.reset();
              this.types = this.typeProduitSvc.getTypesProduits(this.typeProduitSvc.typeProduitList, true);
              this.sites = this.auth2Svc.utilisateur.sites;
              this.declinaisons = filtersBackEnd.declinaisonsAutorisees;
              this.appellations = this.appellationsSvc.appellationsEnvironnement;
              this.famillesGemrcn = filtersBackEnd.famillesGemrcn;
              if (this.famillesGemrcnFromCellSelected && this.famillesGemrcnFromCellSelected.length)
                this.famillesGemrcn = this.famillesGemrcn.filter(f => this.famillesGemrcnFromCellSelected.filter(ff => ff.id === f.id).length);
              this.famillesPlats = this.fpSvc.familleProduitList.filter(item => item.fabrique === true);
              this.planAlimentaire = filtersBackEnd.planAlimentaire;
              this.numeroSemainePlanAlimentaire = filtersBackEnd.numeroSemainePlanAlimentaire;
              this.libelle = this.searchSupplier.platSearch;
            }
            this.initCustomStore();
            return of(null);
      }),
      catchError(err => this.utils.handleError(err)))
      .subscribe((response) => {
        this.displayDialog = true;
        this.titleDialog = `AJOUTER DES ${this.searchSupplier?.decoupageRepas?.libelle.toUpperCase()}S pour la prestation ${this.searchSupplier?.prestation?.libelle.toUpperCase()} - ${this.searchSupplier?.repas?.libelle.toUpperCase()} - du ${this.utils.getFrenchDate_ddd_ll(this.searchSupplier?.dateMenu)}`;
        this.cd.markForCheck();
      });
  };

  /**
   * Notifier les abonnés des produits declinaisons sélectionnés
   */
  addProduitsDeclinaisons: () => void = () => {
    if (!this.utils.isCollectionNullOrEmpty(this.produitsDeclinaisonsInCart)) {
      this.searchSupplier.produitsDeclinaisonsInCart = this.produitsDeclinaisonsInCart;
      if (this.searchSupplier.typeDialog === SEARCH_PLATS_TYPE_DIALOG.MENUS) {
        this.menusComposSvc.announceProduitsDeclinaisonsFromSearch(this.searchSupplier);
      } else if (this.searchSupplier.typeDialog === SEARCH_PLATS_TYPE_DIALOG.PLC) {
        this.mcPlcSvc.announceProduitDeclinaisonFromSearch(this.searchSupplier);
      }
      this.displayDialog = false;
    }
  };

  showCart = (): void => {
    this.displayCart = true;
  };

  removeProduitDeclinaisonInCart = (pdInCart: ProduitDeclinaisonDTO): void => {
    if (this.produitsDeclinaisonsInCart && this.produitsDeclinaisonsInCart.length) {
      const idxToRemove = this.produitsDeclinaisonsInCart.findIndex(p => p.id === pdInCart.id);
      this.produitsDeclinaisonsInCart.splice(idxToRemove, 1);
      this.refreshLazyLoadingSelectedRow();
    }
  };

  eraseCart = (): void => {
    this.produitsDeclinaisonsInCart = [];
    this.displayCart = false;
  };

  onHideCart = (): void => {
    this.displayCart = false;
  };

  onHideAjoutComposants = (): void => {
    this.resetFieldSearch();
    this.displayDialog = false;
  };

  getProduitAllergeneList = (pas: ObjectDTO[], property: string): string => {
    if (!this.utils.isCollectionNullOrEmpty(pas)) {
      return pas.map(pa => pa[property]).join(', ');
    }
    return '';
  };

  disableAddPlats = (): boolean => this.searchSupplier && this.produitsDeclinaisonsInCart.length > this.searchSupplier.maxItemsPanier;

  canHideEcartIcon = (): boolean => this.searchSupplier && this.produitsDeclinaisonsInCart.length >= this.searchSupplier.maxItemsPanier;

  changeSearchMode = ($event: any) => {
    this.searchSupplier.searchMode = $event;
    this.searchSupplier.platSearch = this.libelle;
    this.grid.instance.refresh();
  };

  getProductsSelected = (): ProduitDeclinaisonDTO[] => this.produitsDeclinaisonsInCart;

  checkPrixAchatMax = (): void => {
    if (this.prixAchatMax < 0) {
      this.prixAchatMax = 0;
    }
    if (this.prixAchatMin > this.prixAchatMax) {
      this.prixAchatMin = this.prixAchatMax;
    }
  };

  checkPrixAchatMin = (): void => {
    if (this.prixAchatMin < 0) {
      this.prixAchatMin = 0;
    }
    if (this.prixAchatMin > this.prixAchatMax) {
      this.prixAchatMin = this.prixAchatMax;
    }
  };
}

