import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {SeuilPmsDto} from "../../../../core/dtos/pms/seuil-pms-dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {SeuilPmsService} from "../../../../core/services/pms/seuil-pms.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {EquipementPmsDto} from "../../../../core/dtos/pms/equipement-pms-dto";
import {EquipementPmsService} from "../../../../core/services/pms/equipement-pms.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {FormFieldFileSupplier} from "../../../../core/suppliers/form-field-file-supplier";
import {TypeEquipementPmsService} from "../../../../core/services/pms/type-equipement-pms.service";
import {TypeEquipementPmsDto} from "../../../../core/dtos/pms/type-equipement-pms-dto";
import DataSource from "devextreme/data/data_source";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-equipement-dialog',
  templateUrl: './pms-equipement-dialog.component.html',
  styleUrls: ['./pms-equipement-dialog.component.scss']
})
export class PmsEquipementDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  equipement: EquipementPmsDto;
  seuilsDataSource: DataSource;
  typesDataSource: DataSource;
  sitesList: SiteDTO[];
  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});
  previewFile: File;
  docError = false;
  field: FormFieldFileSupplier;

  siteSelected: SiteDTO;
  seuilSelected: SeuilPmsDto;
  typeSelected: TypeEquipementPmsDto;

  dialogTitle = 'Modification d\'un équipement';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private equipementPmsSvc: EquipementPmsService,
              private seuilPmsSvc: SeuilPmsService,
              private typeEquipementPmsSvc: TypeEquipementPmsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.openDialogVarianteSubscription();
    this.findAllLocalSites();
    this.initSeuilsPms();
    this.initTypesEquipement();
    this.initHasPms();
    this.initField();
    if (document.getElementById('previewImage')) {
      document.getElementById('previewImage').setAttribute('src', null);
    }
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  initSeuilsPms = (): void => {
    this.seuilPmsSvc.getAll()
      .subscribe(response => {
        this.seuilsDataSource = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
      });
  };

  initTypesEquipement = (): void => {
    this.typeEquipementPmsSvc.getAll()
      .subscribe(response => {
        this.typesDataSource = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
      })
  };

  initField = (): void => {
    /**
     * initialisation de la variable a utilisé pour l'affichage de l'image avec yo-img-entity
     */
    this.field = new FormFieldFileSupplier({
      key: 'file',
      label: 'Logo',
      readonly: !this.canModify(),
      value: '',
      entityName: this.equipementPmsSvc.getEntityName().toLowerCase(),
      required: false,
      refresh: new Date().getTime(),
      ordre: 1
    });
  };

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.equipementPmsSvc.openDialog$
      .subscribe((e: EquipementPmsDto) => {
        this.displayDialog = true;
        this.previewFile = null;
        if (!e) {
          this.forUpdate = false;
          this.equipement = new EquipementPmsDto();
          this.equipement.id = null;
          this.dialogTitle = 'Création d\'un équipement';
        } else {
          this.forUpdate = true;
          this.equipement = e;
          this.seuilSelected = e.seuil;
          this.typeSelected = e.type;
          this.siteSelected = e.site;
          this.dialogTitle = 'Modification d\'un équipement';
        }

        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  compare = (data1: any, data2: any): boolean => {
    if (data1 && data2) {
      return data1.id === data2.id;
    }
    return null;
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.siteSelected = null;
    this.seuilSelected = null;
    this.typeSelected = null;
  };

  canModify = (): boolean => {
    if (!this.equipement || !this.equipement.site || !this.equipement.site.id) return this.hasPms;

    let response: boolean = false;

    if (this.equipement && this.equipement.site)
      response = this.auth2Svc.isSiteLocal(this.equipement.site?.id);

    return response && this.hasPms;
  }

  /**
   * fonction qui sauvegarde l'équipement pms créé ou modifié
   */
  save = async (): Promise<void> => {
    if (this.form.valid) {
      this.equipement.libelle = this.form.controls['libelle'].value;
      this.equipement.image = this.form.controls['field'].value;
      this.equipement.seuil = this.seuilsDataSource.items().find(seuil => seuil.id === this.form.controls['seuil'].value);
      this.equipement.type = this.typesDataSource.items().find(type => type.id === this.form.controls['type'].value);
      this.equipement.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);

      this.equipementPmsSvc.save(this.equipement, this.equipement.image)
        .subscribe(response => {
          this.equipementPmsSvc.announceSaved(response.one, this.equipement.id !== undefined && this.equipement.id !== null && this.equipement.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'équipement a été enregistrée avec succès`);
          this.closeDialog();
          this.initForm();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  /**
   * Méthode qui permet d'initialiser le formulaire pour la création ou la modification d'un équipement pms
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.equipement ? this.equipement.libelle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]),
      field: new FormControl(this.equipement ? this.equipement.image : ''),
      seuil: new FormControl(this.equipement ? this.equipement.seuil : null),
      type: new FormControl(this.equipement ? this.equipement.type : null),
      site: new FormControl(this.equipement ? this.equipement.site : null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Equipements (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

  /**
   * Fonction qui permet de prévisualiser l'image téléchargé avant sa sauvegarde
   */
  previewImage = (): void => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      document.getElementById('previewImage').setAttribute('src', e.target.result);
    }
    reader.readAsDataURL(this.previewFile);
  };

  /**
   * Fonction qui récupère l'image puis qui appelle la fonction previewImage
   * pour prévisualiser celle-ci avant de l'enregistrer
   * @param event
   */
  onFileChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.previewFile = event.target.files[0];
      if (!this.utils.isNullOrEmpty(this.previewFile)) {
        // previsualiser l'image avant de l'enregistrer
        this.previewImage();
        this.form.get("field").setValue(this.previewFile);
      }
    }
  };
}
