import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PlanProductionDTO} from '../../../../core/dtos/plan-production-dto';
import {SelectItem, TreeNode} from 'primeng/api';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {UtilsService} from '../../../../core/utils/utils.service';
import * as moment from 'moment';
import {sortBy as _sortBy} from 'lodash'
import {saveAs as fs_saveAs} from 'file-saver';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {ProductionService, TYPE_JOUR} from '../../../../core/services/gestion-production/production.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {BaseProductionComponent} from '../../../../shared/base-production/base-production.component';
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {PlanProductionDetailDTO} from "../../../../core/dtos/plan-production-detail-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-print-settings',
  templateUrl: './print-settings.component.html',
  styleUrls: ['./print-settings.component.scss']
})
export class PrintSettingsComponent extends BaseProductionComponent implements OnInit {

  ppDetailsList: any[] = [];
  _uniteDeProduction__EquipeDTOs: UniteDeProduction__EquipeDTO[] = [];
  _equipesItems: any;
  equipesSelected: TreeNode[] = [];
  jourOblibatoire: string;
  typesJours: SelectItem[] =
    [
      {label: 'Jour de fabrication', value: 'jourFab'},
      {label: 'Jour de consommation', value: 'jourConso'},
    ];
  form: FormGroup;

  printModeOptions = [
    {label:'normal', value:FICHE_TECHNIQUE_PRINT_MODE.NORMAL},
    {label:'condensée', value:FICHE_TECHNIQUE_PRINT_MODE.CONDENSEE}
  ];

  printModeOptions2 = [
    {label:'paysage', value:FICHE_TECHNIQUE_PRINT_MODE.PAYSAGE},
    {label:'portrait', value:FICHE_TECHNIQUE_PRINT_MODE.PORTRAIT}
  ];

  /**
   * Imprimer uniquement les recettes qui ont des effectifs
   */
  printOnlyWithParts: boolean = false;

  constructor(public utils: UtilsService,
              public domSvc: DomSanitizer,
              private gfs: GenericFormService,
              private productionSvc: ProductionService,
              private toastSvc: ToastService) {
    super();
  }

  _planProd: PlanProductionDTO;

  @Input('planProd') set planProd(value: PlanProductionDTO) {
    this._planProd = value;
    if (this._planProd) {
      this.dialogTitle = `Édition "${this._planProd.libelle}"`
      this.initForm(new PlanProdPrintSupplier());
      this.initSubscriptionFichesTechniques();
    }

  }

  @Input('datesConso') set datesConso(values: Date[]) {

    this._datesConso = [];
    if (!this.utils.isCollectionNullOrEmpty(values)) {
      values = _sortBy(values);
      values.map(dateConso => this._datesConso.push({
        label: this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(dateConso)),
        value: dateConso
      }))
    }

  }

  _datesFab: SelectItem[];

  @Input('datesFab') set datesFab(values: Date[]) {

    this._datesFab = [];
    if (!this.utils.isCollectionNullOrEmpty(values)) {
      values = _sortBy(values);
      values.map(dateFab => this._datesFab.push({
        label: this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(dateFab)),
        value: dateFab
      }))
    }
  }

  @Input('equipes') set equipes(values: UniteDeProduction__EquipeDTO[]) {

    this._uniteDeProduction__EquipeDTOs = values;

    this._equipesItems = { data: [] };

    // Les items sont classés alphabétiquement suivant
    // - le zoneDeProductionLibelle
    // - le equipeLibelle
    this._equipesItems.data.sort((a: any, b: any) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  }

  initForm = (printSupplier: PlanProdPrintSupplier): void => {
    this.form = new FormGroup({
      typeJour: new FormControl(printSupplier.selectedTypeJour, Validators.required),
      detailJourConso: new FormControl(printSupplier.detailJourConso, Validators.required),
      detailJourFab: new FormControl(printSupplier.detailJourFab, Validators.required),
      selectedJoursFab: new FormControl(printSupplier.selectedJoursFab),
      selectedJoursConso: new FormControl(printSupplier.selectedJoursConso),
      selectedEquipes: new FormControl(printSupplier.selectedEquipes),
      printMode: new FormControl(FICHE_TECHNIQUE_PRINT_MODE.NORMAL),
      printModeOrientation: new FormControl(FICHE_TECHNIQUE_PRINT_MODE.PAYSAGE),
      ppDetails: new FormControl([], Validators.required),
      ignoreTeam: new FormControl(false)
    });
  };

  ngOnInit() {
    this.initForm(new PlanProdPrintSupplier());
    this.initSubscriptionFichesTechniques();
  }

  /**
   * En langage métier, fiche technique = plat
   */
  initSubscriptionFichesTechniques = (): void => {
    if (this._planProd && this._planProd.id) {
      this.productionSvc.getPlanProductionDetailsForFiltersSettingsPrint(this._planProd, this.form)
        .subscribe((response: ResponseWrapper<PlanProductionDetailDTO>) => {
          this.ppDetailsList = [];
          response.resultList.forEach(p => {
            if (!this.ppDetailsList.filter(x => x.id === p.idProduit).length) {
              this.ppDetailsList.push({ id: p.idProduit, label: p.libelleProduit });
            }
          });
        });
    }
  };

  closeDialog = (): void => {
    this.displayDialog = false;

    // Réinitialisation de la form pour y supprimer les sélections.
    this.initForm(new PlanProdPrintSupplier());
    this.onClose.emit(this.displayDialog);
  };

  updatePlats: () => void = () => {
    this.initSubscriptionFichesTechniques();
  };


  printPlanProd = (): void => {
    this.initEquipesSelectedForFichesTechniques();
    this.gfs.validateAllFormFields(this.form);

    if (this.utils.isNullOrEmpty(this.jourOblibatoire)) {
      this.productionSvc.printRecapFabPDF(this._planProd, this.form).subscribe(response => {
        const fileURL = URL.createObjectURL(response);
        window.open(fileURL, '_blank');
      });
    }

  };

  initEquipesSelectedForFichesTechniques = (): void => {
    let tmp = this.equipesSelected.filter(r => r.parent).map(r => ({ id: parseInt(r.data, 10) })); // equiv reduce
    this.form.controls["selectedEquipes"].setValue(tmp);
  };

  /**
   * Impression des fiches techniques.
   */
  printFichesTechniques = (): void => {
    this.initEquipesSelectedForFichesTechniques();
    this.productionSvc.printFichesTechniques(this._planProd, this.form, this.printOnlyWithParts).subscribe(response => {
        try {
          if (response.size) {
            let blob = new Blob([response], {type: 'application/pdf'});
            fs_saveAs(blob, `edition-fiche-technique.pdf`);
          } else {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Aucune fiche technique ne correspond aux jours sélectionnés`);
          }
        } catch (exception) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Aucune fiche technique ne correspond aux jours sélectionnés`);
        }
      }
    );
  };

  changeJourObligatoire = (): void => {
    this.updateJourObligatoire();
  };

  updateJourObligatoire = (): void => {
    const typeJour = this.form.controls['typeJour'].value;
    let jourConso: boolean = true;
    if (typeJour === 'jourFab')
      jourConso = false;

    let listeDates: Date[] = this.form.controls['selectedJoursConso'].value;
    if (typeJour === 'jourFab')
      listeDates = this.form.controls['selectedJoursFab'].value;

    // Lancement de la recherche
    this.productionSvc.getByIdAndDatesPlanProduction(this._planProd.id, listeDates, jourConso).subscribe(response => {
      let jourObligatoire = '';
      // Récupération des jours concernés
      let selectedJours: number[] = this.form.controls['selectedJoursConso'].value;
      if (typeJour === 'jourFab')
        selectedJours = this.form.controls['selectedJoursFab'].value;

      // Si aucun jour concerné : message utilisateur dans l'interface graphique
      if (this.utils.isCollectionNullOrEmpty(selectedJours)) {
        const warning = `<i class="fas fa-warning"></i>`;
        jourObligatoire = typeJour === 'jourFab' ? `${warning} Veuillez sélectionner des jours de fabrication` : `${warning} Veuillez sélectionner des jours de consommation`;
      }

      this._equipesItems = this.buildEquipesItems(response.resultList);
      this.jourOblibatoire = jourObligatoire;
      this.updatePlats();
    });
  };

  /**
   * Indique s'il est possible d'imprimer
   */
  canPrint = (): boolean => {
    const typeJour: TYPE_JOUR = this.form.controls['typeJour'].value;
    let selectedJours = null;

    if (typeJour == 'jourFab')
      selectedJours = this.form.controls['selectedJoursFab'].value;
    else
      selectedJours = this.form.controls['selectedJoursConso'].value;

    return this.equipesSelected && this.equipesSelected.length && !this.utils.isCollectionNullOrEmpty(selectedJours);
  };

  buildEquipesItems = (uniteDeProduction__EquipeDTOs: UniteDeProduction__EquipeDTO[]): any => {

    // Le tableau de SelectItem à renvoyer
    let equipeItems: any = { data: [] };

    if (!this.utils.isCollectionNullOrEmpty(uniteDeProduction__EquipeDTOs)) {
      equipeItems = uniteDeProduction__EquipeDTOs.map(upe =>
        ({
          "label": upe.zoneDeProductionLibelle,
          "data": upe.id,
          "expandedIcon": "pi pi-cog",
          "collapsedIcon": "pi pi-cog",
          "children": [],
          "expanded": true
        })
      );

      uniteDeProduction__EquipeDTOs.forEach(upe => {
        equipeItems.forEach(atelier => {
          if (upe.zoneDeProductionLibelle === atelier.label &&
            !atelier.children.filter(x => x.label === upe.equipeLibelle).length ) {
            atelier.children.push({
              "label": upe.equipeLibelle,
              "data": upe.id,
              "icon": "pi pi-users"
            });
          }
          atelier.children.sort();
        });
      });

    }

    return equipeItems;
  };


}


export class PlanProdPrintSupplier {

  selectedTypeJour: TYPE_JOUR = 'jourFab';
  detailJourConso: boolean = false;
  detailJourFab: boolean = false;
  selectedJoursFab: Date[] = [];
  selectedJoursConso: Date[] = [];
  selectedEquipes: UniteDeProduction__EquipeDTO[] = [];
  printMode: FICHE_TECHNIQUE_PRINT_MODE = FICHE_TECHNIQUE_PRINT_MODE.PAYSAGE;
  ppDetails: any[] = [];
}

export enum FICHE_TECHNIQUE_PRINT_MODE {
  PAYSAGE = 'PAYSAGE',
  PORTRAIT = 'PORTRAIT',
  CONDENSEE = 'CONDENSEE',
  NORMAL = 'NORMAL'
}
