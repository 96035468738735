import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  DialogRecherchePlatsSupplier,
  MenusPlanning2Service
} from '../../../core/services/gestionmenus/menus-planning2.service';
import {CelluleTableauDTO} from '../../../core/dtos/gestionmenus/planning/cellule-tableau-dto';
import {UtilsService} from '../../../core/utils/utils.service';
import {MenuCompositionDTO} from '../../../core/dtos/menucomposition-dto';
import {DROP_ACTION, MSG_KEY, MSG_SEVERITY, SEARCH_PLATS_TYPE_DIALOG, USER_PREFERENCE} from '../../../core/constants';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {PreferencesUtilisateurService} from '../../../core/services/preferences-utilisateur.service';
import {MenuItem, TreeNode} from 'primeng/api';
import * as moment from 'moment';
import {DecoupageRepasDTO} from '../../../core/dtos/decoupagerepas-dto';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {Subscription} from 'rxjs';
import {MenusDragndropService} from '../../../core/services/gestionmenus/menus-dragndrop.service';
import {DropMenu} from '../../../core/suppliers/gestionmenus/dropmenu';
import {MenusToolbarService} from '../../../core/services/gestionmenus/menus-toolbar.service';
import {find as _find} from 'lodash';
import {Table} from 'primeng/table';
import {Menu} from "primeng/menu";
import {ContratmenuDTO} from "../../../core/dtos/contratmenu-dto";
import {ToastService} from "../../../core/services/technique/toast.service";
import {RegimeAlimentaireDTO} from "../../../core/dtos/regime-alimentaire-dto";

@Component({
  selector: 'yo-decoupage-repas2',
  templateUrl: './decoupage-repas2.component.html',
  styleUrls: ['./decoupage-repas2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecoupageRepas2Component implements OnInit, OnDestroy {

  subPreferences: Subscription;
  displayCoutsUnitairesPonderes = false;
  displayCoutsUnitaires = false;
  displayAppellations = false;
  displayAllergenes = false;
  displayFamillesGemrcn = false;
  displayTauxDePrise = false;

  decoupageRepasToDisplayList: TreeNode[] = [];
  subDecoupageRepasDisplaySettings: Subscription;
  hasToDisplay: boolean = true;

  itemsMenuDecoupage: MenuItem[] = [];
  itemsMenuComposition: MenuItem[] = [];

  contratMenuSelected: ContratmenuDTO;
  regimeSelected: RegimeAlimentaireDTO;

  subContratMenuSelected: Subscription;
  subRegimeSelected: Subscription;

  @Input('cellule') cellule: CelluleTableauDTO;
  @ViewChild('table') table: Table;
  @ViewChild('menuDecoupage') menuDecoupage: Menu;
  @ViewChild('menuMenuComposition') menuMenuComposition: Menu;


  constructor(public  mp2Svc: MenusPlanning2Service,
              private cd: ChangeDetectorRef,
              public auth2Svc: Auth2Service,
              public utils: UtilsService,
              private prefUserSvc: PreferencesUtilisateurService,
              public menuDndSvc: MenusDragndropService,
              public menuToolbarSvc: MenusToolbarService,
              private menusToolbarSvc: MenusToolbarService,
              private toastSvc: ToastService) {
  }


  ngOnInit() {
    this.allSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPreferences);
    this.utils.unsubscribe(this.subDecoupageRepasDisplaySettings);
    this.utils.unsubscribe(this.subContratMenuSelected);
    this.utils.unsubscribe(this.subRegimeSelected);
  }

  allSubscription = () => {
    this.subContratMenuSelected = this.menuToolbarSvc.contratMenuSelected$.subscribe(contratMenu => {
      this.contratMenuSelected = contratMenu;
    });

    this.subRegimeSelected = this.menusToolbarSvc.regime$.subscribe(regime => this.regimeSelected = regime );

    this.subPreferences = this.prefUserSvc.preferencesOfUser$.subscribe(item => {
      this.setVisibilityInformation();
      this.cd.markForCheck();
    });

    // affichage decoupage repas
    this.subDecoupageRepasDisplaySettings = this.mp2Svc.decoupageRepasDisplaySettings$.subscribe(data => {
      this.decoupageRepasToDisplayList = data;
      this.hasToDisplayBody(this.cellule);
    })
  };

  getMenuComposition = (cellule: CelluleTableauDTO, idxChoix: number): MenuCompositionDTO => this.mp2Svc.getMenuComposition(cellule, idxChoix);

  deleteMenuComposition = (menuComposition: MenuCompositionDTO, ordre: number) => {
    const ids = [menuComposition.id];
    this.mp2Svc.deleteMenusCompositions(ids).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.cellule.mapMenuComposition[ordre] = undefined;
        this.cd.markForCheck();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le plat ${menuComposition.produitDeclinaisonLibelle} a été retiré du menu`);
      }
    });
  };

  deleteMenusCompositions = (): void => {
    const ids: number[] = [];
    if (!this.utils.isNullOrEmpty(this.cellule)) {
      if (this.cellule.mapMenuComposition) {
        Object.keys(this.cellule.mapMenuComposition).forEach(key => {
          const val = this.cellule.mapMenuComposition[key];
          if (!this.utils.isNullOrEmpty(val)) {
            ids.push(val.id);
          }
        });
      }
      this.mp2Svc.deleteMenusCompositions(ids).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          Object.keys(this.cellule.mapMenuComposition).forEach(key => this.cellule.mapMenuComposition[key] = undefined);
          this.cd.markForCheck();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les plats ont été supprimés avec succès sur la composante repas '${this.cellule.libelleDecoupageRepas}'`);
        }
      });
    }
  };

  /**
   * Fonction utilitaire pour que la detection angular vois
   * @param index
   * @param item
   */
  trackBySlot = (index, item) => index + '' + item.idContratMenuConviveDecoupage;

  showDialogRecherchePlats = (event: any) => {

    if (this.auth2Svc.isSiteLocal(this.cellule.contratMenuSiteId)) {
      this.cellule.changeDetector = this.cd;
      let count = 0;
      Object.keys(this.cellule.mapMenuComposition).forEach(key => {
        if (!this.utils.isNullOrEmpty(this.cellule.mapMenuComposition[key])) {
          count++;
        }
      });
      if (count === this.cellule.choixMax) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Le nombre de plats maximum a été atteint pour ${this.cellule.libelleDecoupageRepas} ${this.cellule.libelleRepas} ${this.cellule.libelleConvive} ${this.utils.getFrenchDateDDMMYYYY(moment(this.cellule.dateMenu))}. Veuillez supprimer un plat avant de réaliser une recherche`);
      } else {
        const inputs = this.inputsForSearch(this.cellule);
        this.mp2Svc.announceInputRecherchePlats(inputs);
      }
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous ne pouvez pas modifier le menu. Vous n'avez pas les droits sur cet environnement`);
    }
  };

  inputsForSearch = (cell: CelluleTableauDTO): DialogRecherchePlatsSupplier => {
    const dr = new DecoupageRepasDTO();
    dr.libelle = cell.contratMenuConviveDecoupage.decoupageRepasLibelle;
    dr.id = cell.contratMenuConviveDecoupage.decoupageRepasId;

    const repas = new RepasDTO();
    repas.libelle = cell.libelleRepas;
    repas.id = cell.repasId;

    const inputsSearch = new DialogRecherchePlatsSupplier();
    inputsSearch.cmcd = cell.contratMenuConviveDecoupage;
    inputsSearch.dateMenu = moment(cell.dateMenu).toDate();
    inputsSearch.decoupageRepas = dr;
    inputsSearch.repas = repas;
    inputsSearch.choixMax = cell.choixMax;
    inputsSearch.maxItemsPanier = this.mp2Svc.getMaxItemsPanier(cell);
    inputsSearch.cmcContrainteAlim = this.mp2Svc.planningMenus.contrainteAlimentaire;
    inputsSearch.prestation = this.mp2Svc.planningMenus.contratMenuConviveList[0];
    inputsSearch.searchMode = this.prefUserSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.GESTIONMENUS_RECHERCHE_MODE);
    inputsSearch.sourceObject = cell;
    inputsSearch.famillesGemRcn = cell.famillesGemrcn;
    inputsSearch.regimeAlimentaire = this.regimeSelected;
    // 1 = menus compositions, 2 = menus compositions plc
    inputsSearch.typeDialog = SEARCH_PLATS_TYPE_DIALOG.MENUS;

    return inputsSearch;
  };

  /**
   * Modifiable si
   */
  canModify = (): boolean => {
    if (!this.utils.isNullOrEmpty(this.cellule)) {
      return this.auth2Svc.isSiteLocal(this.cellule.contratMenuSiteId);
    }
    return false;
  };

  isDisabled = (siteId: number, nonModifiable: boolean): boolean => {
    const isDisabled = !this.auth2Svc.isSiteLocal(siteId);
    return isDisabled || nonModifiable;
  };


  dragMenuComposition = (menuComposition: MenuCompositionDTO, ordre: number): DropMenu<MenuCompositionDTO> => {
    let dropObj: DropMenu<MenuCompositionDTO> = new DropMenu<MenuCompositionDTO>();
    dropObj.transferObject = menuComposition;
    dropObj.celluleSource = this.cellule;
    dropObj.ordreSource = ordre;
    dropObj.changeDetectorRefSource = this.cd;
    dropObj.dropAction = DROP_ACTION.GM_MOVE_MENUCOMPOSITION;
    return dropObj;
  };

  dropMenuComposition = (event: any, ordre: number) => {
    let dropMenu: DropMenu<MenuCompositionDTO> = event.dragData;
    dropMenu.celluleTarget = this.cellule;
    dropMenu.ordreTarget = ordre;
    dropMenu.changeDetectorRefTarget = this.cd;
    this.menuDndSvc.dropOnCellule(dropMenu);
  };

  /**
   * Indique si le drag and drop est autorisé. Le drag and drop est autorisé si et seulement si
   * le site du ContratMenu sélectionné est le site principal de l'utilisateur connecté.
   * @returns {boolean}
   */
  isDragEnabled = (): boolean => {
    // Le drag and drop est autorisé si et seulement si le site du ContratMenu sélectionné est le site
    // principal de l'utilisateur connecté.
    let response: boolean = this.auth2Svc.isSiteLocal(this.cellule.contratMenuSiteId);

    return response;
  };

  getColspan = () => {
    let colspan = 1;

    if (this.displayAllergenes) {
      colspan++;
    }

    if (this.displayAppellations) {
      colspan++;
    }

    if (this.displayFamillesGemrcn) {
      colspan++;
    }

    if (this.displayCoutsUnitaires) {
      colspan += 2;
    }

    if (this.displayCoutsUnitairesPonderes) {
      colspan++;
    }

    return colspan;
  };

  hasToDisplayBody = (cellule: CelluleTableauDTO) => {
    let result: TreeNode = _find(this.decoupageRepasToDisplayList, (item) => {

      return item.data.niveau === 2 && item.data.idRepas === cellule.repasId && item.data.libelleDecoupageRepas === cellule.libelleDecoupageRepas
    });
    this.hasToDisplay = !this.utils.isNullOrEmpty(result);
    this.cd.markForCheck();
  };

  initItemsMenuDecoupageAndDisplay = (event: any) => {
    this.itemsMenuDecoupage = [
      {
        label: 'Supprimer tous les plats de ' + this.cellule.libelleDecoupageRepas,
        icon: 'fas fa-sync-alt',
        disabled: !this.canModify(),
        command: (event) => this.deleteMenusCompositions()
      }];
    this.menuDecoupage.toggle(event);
  };

  initItemsMenuCompositionAndDisplay = (event: any, cellule: CelluleTableauDTO, menuComposition: MenuCompositionDTO, ordre: number) => {
    this.itemsMenuComposition = [
      {
        label: 'Informations',
        icon: 'fas fa-info fa-sm space',
        command: (event) => this.menuToolbarSvc.openInfosMenuCompo(menuComposition, cellule)
      },
      {
        label: 'Enlever le plat',
        icon: 'fas fa-trash fa-sm space',
        disabled: this.isDisabled(cellule.contratMenuSiteId, cellule.nonModifiable),
        command: (event) => this.deleteMenuComposition(menuComposition, ordre)
      }
    ];
    this.menuMenuComposition.toggle(event);
  };

  setVisibilityInformation = () => {
    this.displayTauxDePrise = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_TAUX_DE_PRISE);
    this.displayAppellations = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_APPELLATIONS);
    this.displayAllergenes = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_ALLERGENES);
    this.displayFamillesGemrcn = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FAMILLES_GEMRCN);

    if (this.utils.isNullOrEmpty(this.contratMenuSelected)) {
      this.displayCoutsUnitaires = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES);
      this.displayCoutsUnitairesPonderes = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES);
    } else {
      this.displayCoutsUnitaires = !this.menuToolbarSvc.isLockedContratMenu(this.contratMenuSelected)
      this.displayCoutsUnitairesPonderes = !this.menuToolbarSvc.isLockedContratMenu(this.contratMenuSelected)
    }
  };

}

